import React from 'react';

import { Col, Row } from 'react-bootstrap';

import {
	DirectusChannelMap,
	DirectusListItem,
	DirectusScreen,
} from '../../shared/models/Directus';
import { chunkArray } from '../../Utils/Utils';
import { displayAnnouncementListItemContent } from './listItem/displays/Announcement.view';
import { displayArticleListItemContent } from './listItem/displays/Article.view';
import { displayEventListItemContent } from './listItem/displays/Event.view';
import { displayLeisureListItemContent } from './listItem/displays/Leisure.view';
import ListItem from './listItem/ListItem';
import NextPageItem from './listItem/NextPageItem';

const MAX_ITEMS_PER_PAGE = 11;
interface Props {
	collectionName: string;
	channelName?: string;
	channelId: number;
	categoryName?: string;
	categoryText: string;
	screen: string;
	page?: string;
	items: DirectusListItem[];
	screenData: DirectusScreen | null;
}

export const ListView = (props: Props) => {
	const {
		channelName,
		channelId,
		categoryText,
		items,
		page,
		collectionName,
		categoryName,
		screenData,
		screen,
	} = props;
	const pages = chunkArray(items, MAX_ITEMS_PER_PAGE);
	const pageNumber = parseInt(page || '1');
	let indexToUse = 0;
	if (pageNumber <= items.length) {
		indexToUse = pageNumber - 1;
	}
	const chunks = pages[indexToUse];

	const channelTitleBadgeText = Object.entries(DirectusChannelMap).find(
		// eslint-disable-next-line
		([key, value]) => key === channelName
	)?.[1];
	return (
		<div className="list-page">
			<Row>
				<h1>
					{channelTitleBadgeText} {categoryText && '›'} {categoryText}
				</h1>
			</Row>
			<Row>
				{chunks.map((cell: DirectusListItem, index: number) => {
					return (
						<Col lg="4" className="list-item-row" key={index}>
							<ListItem
								link={
									collectionName !== 'announcements'
										? '/' +
										  screen +
										  '/' +
										  collectionName +
										  '/' +
										  channelId +
										  '/' +
										  cell.id
										: undefined
								}
								content={
									<>
										{collectionName === 'leisure' &&
											screenData &&
											displayLeisureListItemContent(screenData, cell)}
										{collectionName === 'articles' &&
											screenData &&
											displayArticleListItemContent(screenData, cell)}
										{collectionName === 'events' &&
											screenData &&
											displayEventListItemContent(screenData, cell)}
										{collectionName === 'announcements' &&
											screenData &&
											displayAnnouncementListItemContent(screenData, cell)}
									</>
								}
							/>
						</Col>
					);
				})}
				{pages[indexToUse + 1] && (
					<Col lg="4" className="list-item-row">
						<NextPageItem
							screenId={parseInt(screen)}
							collectionName={collectionName}
							channelId={channelId}
							categoryName={categoryName || ''}
							page={pageNumber + 1}
						/>
					</Col>
				)}
			</Row>
		</div>
	);
};

import React from 'react';

import { Route, Switch, withRouter } from 'react-router-dom';

import { LeisureDetail } from './LeisureDetail.container';

export const LEISURE_URL = '/:screen/leisure/:channelId/:itemId';

function LeisureRouting() {
	return (
		<>
			<Switch>
				<Route path={LEISURE_URL} component={LeisureDetail} />
			</Switch>
		</>
	);
}

export default withRouter(LeisureRouting);

/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';

import { Card, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import './ChannelBox.scss';
import {
	SwipeableHandlers,
	SwipeEventData,
	useSwipeable,
} from 'react-swipeable';

import { useDirectus } from '../../../hooks/directus';
import { RootState } from '../../../store/rootReducer';
import { selectScreenData } from '../../../store/ScreenSlice';
import { distance, shuffleArray } from '../../../Utils/Utils';
import {
	DirectusArticle,
	DirectusChannel,
	DirectusEmergencyService,
	DirectusItem,
	DirectusScreen,
} from '../../models/Directus';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator.view';
import 'leaflet/dist/leaflet.css';
import ArticleView from './displays/Article.view';
import EmergencyServiceView from './displays/EmergencyService.view';
import EventView from './displays/Event.view';
import LeisureView from './displays/Leisure.view';

import { LatLngTuple } from 'leaflet';

interface ChannelBoxProps {
	channel: DirectusChannel;
	screenData: DirectusScreen | null;
}

const queries: {
	// eslint-disable-next-line
	[id: string]: any;
} = {
	articles: {
		fields: ['id', 'title', 'content', 'category', 'image.*'],
		filter: {
			released: {
				_eq: true,
			},
		},
		sort: '-date',
		limit: 3,
	},
	events: {
		fields: [
			'id',
			'title',
			'content',
			'location.*',
			'image.*',
			'date',
			'start',
			'end',
		],
		filter: {
			_and: [
				{
					date: {
						_gte: '$NOW',
					},
				},
				{
					released: {
						_eq: true,
					},
				},
			],
		},
	},
	emergencyservices: {
		fields: ['title', 'location.*', 'start', 'phone', 'end'],
		filter: {
			_and: [
				{
					start: {
						_lte: '$NOW',
					},
				},
				{
					end: {
						_gte: '$NOW',
					},
				},
			],
		},
		limit: 1,
	},
	leisure: {
		fields: ['id', 'title', 'location.*', 'content', 'image.*'],
		filter: {},
	},
};

const innerDisplay: {
	[id: string]: (
		item: DirectusItem,
		channel: DirectusChannel,
		otherItems: DirectusItem[],
		currentItemIndex: number,
		handlers: SwipeableHandlers,
		screenData: DirectusScreen | null
	) => JSX.Element;
} = {
	articles: (
		item: DirectusItem,
		channel: DirectusChannel,
		otherItems: DirectusItem[],
		currentItemIndex: number,
		handlers: SwipeableHandlers,
		screenData: DirectusScreen | null
	) => {
		return (
			<ArticleView
				item={item}
				channel={channel}
				otherItems={otherItems}
				currentItemIndex={currentItemIndex}
				onSwipe={handlers}
			></ArticleView>
		);
	},
	events: (
		item: DirectusItem,
		channel: DirectusChannel,
		otherItems: DirectusItem[],
		currentItemIndex: number,
		handlers: SwipeableHandlers,
		screenData: DirectusScreen | null
	) => {
		return (
			<EventView
				item={item}
				channel={channel}
				otherItems={otherItems}
				currentItemIndex={currentItemIndex}
				onSwipe={handlers}
			></EventView>
		);
	},
	emergencyservices: (
		item: DirectusItem,
		channel: DirectusChannel,
		otherItems: DirectusItem[],
		currentItemIndex: number,
		handlers: SwipeableHandlers,
		screenData: DirectusScreen | null
	) => {
		// sort according to distance
		const lat = 0;
		const lon = 0;

		const pharmacies = otherItems.sort((a, b) => {
			const screenLatLngTuple: LatLngTuple = [lat, lon];
			const aItem = a as DirectusEmergencyService;
			const bItem = b as DirectusEmergencyService;
			const aDistance = distance(
				[aItem.location?.coords.lat, aItem.location?.coords.lon],
				screenLatLngTuple
			);
			const bDistance = distance(
				[bItem.location?.coords.lat, bItem.location?.coords.lon],
				screenLatLngTuple
			);
			return bDistance - aDistance;
		});
		if (pharmacies.length === 0) {
			return <>Keine Notfallapotheke hinterlegt</>;
		}
		return (
			<EmergencyServiceView
				item={pharmacies.slice(0, 1)[0]}
				channel={channel}
				otherItems={[]}
				currentItemIndex={currentItemIndex}
				onSwipe={handlers}
			></EmergencyServiceView>
		);
	},
	leisure: (
		item: DirectusItem,
		channel: DirectusChannel,
		otherItems: DirectusItem[],
		currentItemIndex: number,
		handlers: SwipeableHandlers
	) => {
		return (
			<LeisureView
				item={item}
				channel={channel}
				otherItems={otherItems}
				currentItemIndex={currentItemIndex}
				onSwipe={handlers}
			></LeisureView>
		);
	},
};

export interface DisplayProps {
	item: DirectusItem;
	channel: DirectusChannel;
	otherItems: DirectusItem[];
	currentItemIndex: number;
	onSwipe: SwipeableHandlers;
}

function ChannelBoxView(props: ChannelBoxProps): JSX.Element {
	const { channel } = props;
	let items: DirectusItem[] = [];

	const screenData = useSelector((state: RootState) =>
		selectScreenData(state.screen)
	);

	const interval = screenData?.options?.intervals.channels || 30000;
	const directusCollection = channel.category.replace('-', '');

	const [result, error, state] = useDirectus(async (client) => {
		const query = queries[directusCollection];
		if (['articles', 'events'].indexOf(channel.category) !== -1) {
			query['filter']['municipalities'] = {
				// eslint-disable-next-line @typescript-eslint/camelcase
				municipality_id: {
					_eq: screenData?.municipality.id,
				},
			};
		}
		if (channel.limit && !channel.randomize) {
			query['limit'] = channel.limit;
		}

		const results = await client
			.items<any, DirectusArticle>(directusCollection)
			.readMany(query);

		if (channel.randomize && results.data) {
			results.data = shuffleArray(results.data).slice(0, channel.limit);
		}
		return results;
	});

	const [item, setItem] = useState<DirectusItem | null>(null);
	const [index, setIndex] = useState(0);
	if (
		state === 'success' &&
		result &&
		result.data &&
		Array.isArray(result.data)
	) {
		items = result.data as DirectusItem[];
	}

	if (state === 'success' && !result) {
		throw Error('Could not fetch screen data');
	}

	// eslint-disable-next-line
	let intervalId: any;

	const handlers = useSwipeable({
		onSwiped: (eventData: SwipeEventData) => {
			const { dir } = eventData;
			if (dir === 'Right') {
				if (index > 0) {
					setIndex(index - 1);
				}
			} else if (dir === 'Left') {
				if (index < items.length - 1) {
					setIndex(index + 1);
				}
			}
			setItem(items[index]);
		},
		...{
			delta: 10, // min distance(px) before a swipe starts
			preventDefaultTouchmoveEvent: false, // call e.preventDefault *See Details*
			trackTouch: true, // track touch input
			trackMouse: true, // track mouse input
			rotationAngle: 0, // set a rotation angle
		},
	});
	useEffect(() => {
		setItem(index < items.length ? items[index] : null);
		if (true) {
			// eslint-disable-next-line
			intervalId = setInterval(() => {
				if (index + 1 < items.length) {
					setIndex(index + 1);
				} else {
					// eslint-disable-next-line
					setIndex(0)
				}
				setItem(items[index]);
			}, interval);
		}
		return () => {
			clearInterval(intervalId);
		};
	}, [items, intervalId, channel, state, index]);
	return (
		<>
			<Col xs={channel.width} lg={channel.width} className="channel-box">
				<Card border="primary" {...handlers}>
					{state === 'errored' && error && (
						<b>Kanal konnte nicht geladen werden</b>
					)}
					{state === 'loading' && <LoadingIndicator />}
					{state === 'success' &&
						item &&
						innerDisplay[directusCollection](
							item,
							channel,
							items,
							index,
							handlers,
							screenData
						)}
					{state === 'success' && items && items.length === 0 && (
						<b>
							Für diese Inhaltskachel steht derzeit kein Inhalt zur Verfügung
						</b>
					)}
				</Card>
			</Col>
		</>
	);
}

export default ChannelBoxView;

import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { useDirectus } from '../../../hooks/directus';
import { RootState } from '../../../store/rootReducer';
import { selectScreenData } from '../../../store/ScreenSlice';
import { getThumbnail } from '../../../Utils/Utils';
import { DirectusBanner, DirectusBannerContainer } from '../../models/Directus';

import 'leaflet/dist/leaflet.css';
import './Banner.scss';
import { QueryMany } from '@directus/sdk';

export interface DisplayProps {
	container: DirectusBannerContainer;
}

function BannerView(props: DisplayProps): JSX.Element {
	const { container } = props;
	let items: DirectusBanner[] = [];

	const screenData = useSelector((state: RootState) =>
		selectScreenData(state.screen)
	);

	const interval = screenData?.options?.intervals.ads || 10000;

	// eslint-disable-next-line
	const [result, error, state] = useDirectus(async client => {
		const query: QueryMany<DirectusBanner> = {
			fields: ['logo.*', 'text', 'logo_width'],
			filter: {
				container: {
					_eq: container.id,
				},
			},
		};
		return await client
			.items<'banners', DirectusBanner>('banners')
			.readMany(query);
	});
	const [item, setItem] = useState<DirectusBanner | null>(null);
	const [index, setIndex] = useState(0);
	if (
		state === 'success' &&
		result &&
		result.data &&
		Array.isArray(result.data)
	) {
		const rawItems = result.data as DirectusBanner[];
		items = rawItems;
	}

	if (state === 'success' && !result) {
		throw Error('Could not fetch screen data');
	}

	let intervalId: any;
	useEffect(() => {
		setItem(index < items.length ? items[index] : null);
		if (true) {
			// eslint-disable-next-line
			intervalId = setInterval(() => {
				if (index + 1 < items.length) {
					setIndex(index + 1);
				} else {
					// eslint-disable-next-line
					setIndex(0)
				}
				setItem(items[index]);
			}, interval);
		}
		return () => {
			clearInterval(intervalId);
		};
	}, [items, intervalId, state, index]);
	const title = item?.text
		? item?.text.split('\n').map((i, index) => (
				<React.Fragment key={index}>
					{i}
					<br />
				</React.Fragment>
		  ))
		: null;

	if (!item?.logo && item?.text) {
		// only text
		return (
			<Row>
				<Col lg="12" className="ad-content-text">
					{title}
				</Col>
			</Row>
		);
	} else if (item?.logo && !item?.text) {
		// only logo
		return (
			<Row>
				<Col lg="12" className="ad-only-logo">
					{item?.logo && (
						<img
							className="ad-content-logo"
							alt="ad-content-logo"
							src={getThumbnail(item?.logo)}
						/>
					)}
				</Col>
			</Row>
		);
	} else {
		// both
		return (
			<Row>
				<Col lg={item?.logo_width}>
					{item?.logo && (
						<img
							className="ad-content-logo"
							alt="ad-content-logo"
							src={getThumbnail(item?.logo)}
						/>
					)}
				</Col>
				<Col
					lg={12 - (item ? item?.logo_width : 0)}
					className="ad-content-text"
				>
					{title}
				</Col>
			</Row>
		);
	}
}

export default BannerView;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useDirectus } from '../hooks/directus';
import {
	DirectusBannerContainer,
	DirectusChannel,
	DirectusScreen,
	DirectusScreenDataApiResponse,
} from '../shared/models/Directus';
import { AppThunk } from './store';

export interface ScreenState {
	screenData: DirectusScreen | null;
	channels: DirectusChannel[];
	bannerContainer: DirectusBannerContainer[];
	loading: boolean;
	error: Error | null;
	isIdle: boolean;
	reloadTimeLeft: number;
}

export const initialState: ScreenState = {
	screenData: null,
	channels: [],
	bannerContainer: [],
	loading: false,
	error: null,
	isIdle: false,
	reloadTimeLeft: 0,
};

const screen = createSlice({
	name: 'screen',
	initialState,
	reducers: {
		getScreenDataStart(state) {
			state.loading = true;
			state.error = null;
		},
		getScreenDataSuccess(
			state,
			action: PayloadAction<DirectusScreenDataApiResponse>
		) {
			/**
			 * Directus seems not to allow one-to-many relationshipts properly
			 * For this purpose, the slots are done as many-to-many, but we need to get rid of the junction table artefacts here.
			 */
			let channels: DirectusChannel[] = [];
			// eslint-disable-next-line
			action.payload.channels.map((s: any) => {
				const channel = s.channels_id;
				channel.parentScreen = action.payload.id;
				channels.push(channel);
			});
			// sort the slots
			channels = channels.sort(
				(a: DirectusChannel, b: DirectusChannel) => a?.order - b?.order
			);

			let bannerContainer: DirectusBannerContainer[] = [];
			// eslint-disable-next-line
			action.payload.bannercontainer.forEach((s: any) => {
				if (s.bannercontainer_id) {
					bannerContainer.push(s.bannercontainer_id);
				}
			});
			console.log(bannerContainer);
			// sort the slots
			bannerContainer = bannerContainer.sort(
				(a: DirectusBannerContainer, b: DirectusBannerContainer) =>
					a?.order - b?.order
			);
			state.screenData = action.payload;
			state.channels = channels;
			state.bannerContainer = bannerContainer;
			state.loading = false;
			state.error = null;
		},
		getScreenDataFailed(state, action: PayloadAction<Error>) {
			state.loading = false;
			state.error = action.payload;
		},
		setIdleState(state, action: PayloadAction<boolean>) {
			state.isIdle = action.payload;
		},
		setReloadTimeLeft(state, action: PayloadAction<number>) {
			state.reloadTimeLeft = action.payload;
		},
	},
});

export const {
	getScreenDataStart,
	getScreenDataSuccess,
	getScreenDataFailed,
	setIdleState,
	setReloadTimeLeft,
} = screen.actions;

export default screen.reducer;

export const selectScreenData = (state: ScreenState) => state.screenData;

export const selectChannels = (state: ScreenState) => state.channels;

export const selectWifiSettings = (state: ScreenState) =>
	state.screenData?.options.wifiSettings;

export const selectBannerContainer = (state: ScreenState) =>
	state.bannerContainer;

export const selectLoading = (state: ScreenState) => state.loading;

export const selectIdle = (state: ScreenState) => state.isIdle;

export const selectReloadTimeLeft = (state: ScreenState) =>
	state.reloadTimeLeft;

export const selectScreenId = (state: ScreenState) => state.screenData?.id;

export const onGetScreenData =
	(id: number): AppThunk =>
	async (dispatch) => {
		try {
			const [result, error, state] = useDirectus(async (client) => {
				const query = {
					filter: {
						id: {
							_eq: id,
						},
					},
					fields: [
						'owner.*',
						'id',
						'name',
						'title',
						'options',
						'location.*',
						'logo.*',
						'municipality.*',
						'channels.channels_id.category',
						'channels.channels_id.title',
						'channels.channels_id.text',
						'channels.channels_id.randomize',
						'channels.channels_id.limit',
						'channels.channels_id.width',
						'channels.channels_id.order',
						'channels.channels_id.id',
						'channels.channels_id.defaultimage.*',
						'bannercontainer.bannercontainer_id.id',
						'bannercontainer.bannercontainer_id.order',
						'backdrop.*',
						'forecast',
					],
					single: true,
				};
				return await client
					.items<'screens', DirectusScreenDataApiResponse>('screens')
					.readMany(query);
			});

			if (state === 'loading') {
				dispatch(getScreenDataStart());
			}

			if (state === 'errored' && error) {
				dispatch(getScreenDataFailed(error));
			}
			if (state === 'success' && result && result.data) {
				const data = result.data.pop() as DirectusScreenDataApiResponse;
				if (data) {
					dispatch(getScreenDataSuccess(data));
				} else {
					dispatch(getScreenDataFailed(new Error('Invalid screen data')));
				}
			}

			if (state === 'success' && !result) {
				throw Error('Could not fetch screen data');
			}
		} catch (error) {
			dispatch(getScreenDataFailed(error));
		}
	};

export const onIdle = (): AppThunk => (dispatch) => {
	dispatch(setIdleState(true));
};

export const onAction = (): AppThunk => (dispatch) => {
	dispatch(setIdleState(false));
};

export const onReloadTimeLeftChange =
	(time: number): AppThunk =>
	(dispatch) => {
		dispatch(setReloadTimeLeft(time));
	};

import React from 'react';

import { Row } from 'react-bootstrap';

import './ListItem.scss';

interface Props {
	content: React.ReactNode;
	link?: string;
}

function ListItem(props: Props) {
	const { content, link } = props;
	return (
		<a href={link} className="list-item">
			<Row>{content}</Row>
		</a>
	);
}

export default ListItem;

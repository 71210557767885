import { combineReducers } from '@reduxjs/toolkit';

import breakingNewsReducer from './BreakingNewsSlice';
import globalReducer from './GlobalSlice';
import screenReducer from './ScreenSlice';
import weatherSlice from './WeatherSlice';

const rootReducer = combineReducers({
	global: globalReducer,
	screen: screenReducer,
	weather: weatherSlice,
	breakingNews: breakingNewsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const selectGlobalLoading = (state: RootState) =>
	state.screen.loading || state.weather.loading;

export default rootReducer;

import React from 'react';

import { Badge, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../store/rootReducer';
import { selectScreenData } from '../../../../store/ScreenSlice';
import {
	DirectusArticle,
	DirectusArticleCategory,
	DirectusChannel,
} from '../../../models/Directus';

export interface Props {
	item: DirectusArticle;
	channelName: string;
	channel?: DirectusChannel;
}

export default function ArticleDetailView(props: Props) {
	const { item, channel } = props;
	const { title, category, user, content, date } = item;

	const categoryText = Object.entries(DirectusArticleCategory).find(
		// eslint-disable-next-line
		([key, value]) => key === category
	)?.[1];
	const displayDate = new Date(date).toLocaleDateString('de-DE', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});
	const screenData = useSelector((state: RootState) =>
		selectScreenData(state.screen)
	);
	return (
		<>
			<Col lg="12" className="event-body">
				<Row className="event-body-row">
					<Badge>Aktuelles</Badge>
					<Col lg="12">
						<Row>
							<h1>{title}</h1>
						</Row>

						<Row className="detail-meta">
							<span>
								von <span className="detail-meta-author">{user.title} </span> in
								der Kategorie
								<a
									style={{ marginLeft: '0.3vw' }}
									href={
										'/' +
										screenData?.id +
										'/list/articles/' +
										channel?.id +
										'/' +
										category
									}
								>
									{categoryText}
								</a>
							</span>
							<span className="detail-meta-date"> {displayDate}</span>
						</Row>

						<Row className="text-content">
							{content.split('\n').map(function (item, idx) {
								return <span key={idx}>{item}</span>;
							})}
						</Row>
					</Col>
				</Row>
			</Col>
		</>
	);
}

import React from 'react';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './LoadingIndicator.scss';

function LoadingIndicator() {
	return (
		<FontAwesomeIcon
			className="loading-indicator"
			icon={faSpinner}
			size="5x"
			spin
		/>
	);
}

export default LoadingIndicator;

import React from 'react';

import { Route, Switch, withRouter } from 'react-router-dom';

import { Event } from './Event.container';

export const EVENT_URL = '/:screen/events/:channelId/:itemId';

function EventRouting() {
	return (
		<>
			<Switch>
				<Route path={EVENT_URL} component={Event} />
			</Switch>
		</>
	);
}

export default withRouter(EventRouting);

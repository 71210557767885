import { useEffect, useState } from 'react';

import { Directus, TypeMap } from '@directus/sdk';
// main client instance
const BACKEND_URL = process.env.REACT_APP_BACKEND || '';
const client = new Directus(BACKEND_URL);

// define the loading states
export enum ResponseState {
	LOADING = 'loading',
	ERRORED = 'errored',
	SUCCESS = 'success',
}

// eslint-disable-next-line
export function useDirectus<T extends any = any, O extends any = undefined>(
	fn: (client: Directus<TypeMap>, opts?: O) => Promise<T>,
	opts?: O
): [T | undefined, Error | undefined, ResponseState] {
	// save error and response state internally
	const [response, setResponse] = useState<T | undefined>();
	const [error, setError] = useState<Error | undefined>();

	useEffect(() => {
		// will call the API with your provided fn
		const fetcher = async () => {
			try {
				const result = await fn(client);
				// set the response if everything's allright
				setResponse(result);
			} catch (err) {
				// set the error if something went wrong
				setError(err);
			}
		};

		// execute!
		fetcher();
		// eslint-disable-next-line
	}, [])

	return [
		response,
		error,
		error
			? ResponseState.ERRORED
			: response
			? ResponseState.SUCCESS
			: ResponseState.LOADING,
	];
}

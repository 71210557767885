import React from 'react';

import {
	Route,
	RouteComponentProps,
	Switch,
	withRouter,
} from 'react-router-dom';

import { Start } from './Start.container';

export const START_URL = '/:screen';

interface Props extends RouteComponentProps {}

function StartRouting(props: Props) {
	const {
		match: { url },
	} = props;
	return (
		<Switch>
			<Route exact path={url} component={Start} />
		</Switch>
	);
}

export default withRouter(StartRouting);

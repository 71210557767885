import React from 'react';

import { Col, Row } from 'react-bootstrap';

import './HeaderLogo.scss';
import { getThumbnail } from '../../../Utils/Utils';
import { DirectusImage } from '../../models/Directus';

interface HeaderProps {
	title: string | null;
	type: string | null;
	image: DirectusImage | null;
	id: number | null;
}

function HeaderLogo(props: HeaderProps) {
	const { title, image, type, id } = props;

	const imageUrl = getThumbnail(image, 1000);
	return (
		<a href={'/' + id} className="header-link">
			<Row className="header-logo-parent">
				{image && <img alt="Logo" className="header-logo" src={imageUrl} />}
				<Col className="header-and-logo">
					<Row>{type}</Row>
					<Row className="header-logo-name">{title}</Row>
				</Col>
			</Row>
		</a>
	);
}

export default HeaderLogo;

import React, { useState } from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QRCode from 'qrcode.react';

import './WifiBox.scss';
import { Col, Modal, Row } from 'react-bootstrap';

interface WifiBoxProps {
	wifiName: string | null;
	wifiPassword: string | null;
	wifiSecurity: 'WEP' | 'WPA' | 'nopass';
	locationTitle: string;
}

function WifiBox(props: WifiBoxProps) {
	const { wifiName, wifiPassword, wifiSecurity } = props;

	const [wifiInfoOpen, setWifiInfoOpen] = useState(false);
	const qrContent =
		wifiSecurity === 'nopass'
			? `WIFI:T:nopass;S:${wifiName};;;`
			: `WIFI:T:${wifiSecurity};S:${wifiName};P:${wifiPassword};; `;
	return (
		<Col lg="3" className="wifi-box-parent d-none d-lg-block">
			<Modal
				show={wifiInfoOpen}
				onHide={() => setWifiInfoOpen(false)}
				className="wifi-modal"
			>
				<Modal.Body>
					<FontAwesomeIcon
						className="close-wifi-info-box-icon"
						size="2x"
						icon={faTimes}
						onClick={() => setWifiInfoOpen(false)}
					/>
					<div>
						Scannen Sie den QR Code oder suchen Sie nach dem WLAN und geben Sie
						das Passwort ein:
					</div>
					<div>
						<b>WLAN Name: </b>
						{wifiName}
					</div>
					{wifiPassword && (
						<div>
							<b>WLAN Passwort: </b>
							{wifiPassword}
						</div>
					)}
				</Modal.Body>
			</Modal>
			<Row onClick={() => setWifiInfoOpen(true)}>
				<Col className="wifi-text" lg="9">
					<Row className="wifi-text-parent">
						<Col className="wifi-text">
							<Row className="header-column-title">
								Antippen oder scannen für
							</Row>
							<Row className="wifi-text-sub">
								<span>Gratis WLAN</span>
							</Row>
						</Col>
					</Row>
				</Col>
				<Col lg="2" className="qr-parent">
					<QRCode className="wifi-qr-code" value={qrContent} size={300} />
				</Col>
			</Row>
		</Col>
	);
}

export default WifiBox;

import React from 'react';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './LoadingIndicator.scss';

function FullscreenLoadingIndicator() {
	return (
		<div className="fullscreen-loading-indicator-parent">
			<FontAwesomeIcon
				className="fullscreen-loading-indicator"
				icon={faSpinner}
				size="5x"
				spin
			/>
		</div>
	);
}

export default FullscreenLoadingIndicator;

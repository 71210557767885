import React from 'react';

import { Route, Switch, withRouter } from 'react-router-dom';

import { List } from './List.container';

export const LIST_URL =
	'/:screen/list/:collectionName/:channelId/:categoryName/:page?';

function ListRouting() {
	return (
		<>
			<Switch>
				<Route path={LIST_URL} component={List} />
			</Switch>
		</>
	);
}

export default withRouter(ListRouting);

import React from 'react';

import { Col, Image } from 'react-bootstrap';

import {
	DirectusListItem,
	DirectusScreen,
} from '../../../../shared/models/Directus';
import { LIST_TITLE_LENGTH } from '../../../../Utils/EnvironmentSettings';
import { getThumbnail, shortText } from '../../../../Utils/Utils';

export const displayArticleListItemContent = (
	// eslint-disable-next-line
	screenData: DirectusScreen,
	item: DirectusListItem
): JSX.Element => {
	const image = getThumbnail(item.image, 400);

	const textWidth = image ? 9 : 12;
	return (
		<>
			<Col lg={textWidth}>
				<div className="list-item-text-content-container">
					<p className="list-item-meta">
						Von {item.user?.title} am{' '}
						{new Date(item.date).toLocaleDateString('de-DE', {
							year: 'numeric',
							month: '2-digit',
							day: '2-digit',
						})}
					</p>
					<h4>{shortText(item.title, LIST_TITLE_LENGTH)}</h4>
				</div>
			</Col>
			{image && (
				<Col lg="3">
					<Image src={image} className="list-item-image" />
				</Col>
			)}
		</>
	);
};

import React from 'react';

import { Button, Card, Col, Row } from 'react-bootstrap';

import { getThumbnail, shortText, shortTitle } from '../../../../Utils/Utils';
import { DirectusArticle, DirectusItem } from '../../../models/Directus';
import { DisplayProps } from '../ChannelBox.view';

function ArticleView(props: DisplayProps) {
	const { item, channel, otherItems, currentItemIndex, onSwipe } = props;
	const { parentScreen, width } = channel;
	const article = item as DirectusArticle;
	const fullImageUrl = getThumbnail(channel.defaultimage);
	const reequestedSize = width > 2 ? 600 : 400;
	const imageUrl = getThumbnail(article.image, reequestedSize) || fullImageUrl;
	const link = parentScreen + '/articles/' + channel.id + '/' + article.id;
	return (
		<>
			<a href={link} className="no-highlight">
				<Card.Header {...onSwipe}>Aktuelles</Card.Header>
				<Card.Img src={imageUrl} variant="top" {...onSwipe} />
				<Card.Body>
					<Card.Title {...onSwipe}>{shortTitle(article.title)}</Card.Title>
					<Card.Text as="p" {...onSwipe}>
						<span>{article.date}</span>
						<span className="main-text">{shortText(article.content)}</span>
					</Card.Text>
				</Card.Body>
			</a>
			<Card.Footer>
				<Row>
					<Col>
						<Button
							variant="secondary"
							href={
								parentScreen +
								'/list/' +
								channel.category +
								'/' +
								channel.id +
								'/latest'
							}
						>
							Alle ansehen
						</Button>
					</Col>
					<Col>
						<Button href={link}>Mehr erfahren</Button>
					</Col>
				</Row>

				<Row>
					<Col>
						<div className="bullets-parent">
							{otherItems.map((item: DirectusItem, index: number) => {
								return (
									<span
										key={item.id}
										className={`bullet ${
											currentItemIndex === index ? 'bullet-active' : ''
										}`}
									>
										{' '}
									</span>
								);
							})}
						</div>
					</Col>
				</Row>
			</Card.Footer>
		</>
	);
}

export default ArticleView;

import React from 'react';

import QRCode from 'qrcode.react';
import { Col } from 'react-bootstrap';

import {
	DirectusListItem,
	DirectusScreen,
} from '../../../../shared/models/Directus';

export const displayAnnouncementListItemContent = (
	// eslint-disable-next-line
	screenData: DirectusScreen,
	item: DirectusListItem
): JSX.Element => {
	const date = new Date(item.date).toLocaleDateString('de-DE', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});
	return (
		<>
			{item.link && (
				<>
					<Col lg="9">
						<div className="list-item-text-content-container">
							<p className="list-item-meta">
								{date} · <b>{item.municipality?.name}</b>
							</p>
							<p className="list-item-text-content">{item.title}</p>
						</div>
					</Col>
					<Col lg="3">
						<QRCode
							className="list-announcement-qr-code"
							value={item.link}
							size={120}
						/>
					</Col>
				</>
			)}
		</>
	);
};

import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Showcase.scss';
import { DirectusBannerContainer } from './shared/models/Directus';
import BannerView from './shared/views/Banner/Banner.view';
import BreakingNewsItem from './shared/views/BreakingNewsItem/BreakingNewsItem';
import ForecastItem from './shared/views/ForeCastItem/Forecast.view';
import HeaderLogo from './shared/views/HeaderLogo/HeaderLogo';
import HomeButtonView from './shared/views/HomeButton/HomeButton.view';
import FullscreenLoadingIndicator from './shared/views/LoadingIndicator/FullscreenLoadingIndicator';
import WifiBox from './shared/views/WifiBox/WifiBox';
import { SCREEN_TIMEOUT_COUNTDOWN } from './Showcase.container';
import { getBreakingNews, onGetBreakingNews } from './store/BreakingNewsSlice';
import { RootState, selectGlobalLoading } from './store/rootReducer';
import {
	selectBannerContainer,
	selectIdle,
	selectReloadTimeLeft,
	selectScreenData,
	selectWifiSettings,
} from './store/ScreenSlice';
import {
	getCurrentWeather,
	getTomorrowWeather,
	getWeatherForecast,
	onGetWeather,
	Weather,
} from './store/WeatherSlice';
import { getCurrentDateString, getCurrentTimeString } from './Utils/Utils';

interface Props extends RouteComponentProps, React.PropsWithChildren<{}> {}
function InteractiveShowcaseAppView(props: Props): JSX.Element {
	const { children } = props;
	const dispatch = useDispatch();

	dispatch(onGetBreakingNews());
	const screenData = useSelector((state: RootState) =>
		selectScreenData(state.screen)
	);
	const breakingNews = useSelector((state: RootState) =>
		getBreakingNews(state.breakingNews)
	);

	const weatherForecast = useSelector((state: RootState) =>
		getWeatherForecast(state.weather)
	);

	const currentWeather = useSelector((state: RootState) =>
		getCurrentWeather(state.weather)
	);

	const tomorrowWeather = useSelector((state: RootState) =>
		getTomorrowWeather(state.weather)
	);

	const bannerContainer = useSelector((state: RootState) =>
		selectBannerContainer(state.screen)
	);
	const wifiSettings = useSelector((state: RootState) =>
		selectWifiSettings(state.screen)
	);

	const isLoading = useSelector((state: RootState) =>
		selectGlobalLoading(state)
	);

	const isIdle = useSelector((state: RootState) => selectIdle(state.screen));

	const reloadTimeLeft = useSelector((state: RootState) =>
		selectReloadTimeLeft(state.screen)
	);

	const [currentTime, setCurrentTime] = useState<string>('');

	useEffect(() => {
		if (screenData?.location) {
			dispatch(onGetWeather(screenData));
		}
		setCurrentTime(getCurrentTimeString());
	}, [dispatch, screenData]);

	useEffect(() => {
		const dateUpdateIntervalId = setInterval(() => {
			setCurrentTime(getCurrentTimeString());
		}, 1 * 1000);
		return () => {
			clearInterval(dateUpdateIntervalId);
		};
	}, []);

	const { pathname } = useLocation();
	const isAtHome = pathname === '/' + screenData?.id;

	let routeTitle = 'start';

	/** Add CSS classes for better styling */

	const routeNeedles = {
		articles: 'articles-detail',
		leisure: 'leisure-detail',
		events: 'events-detail',
		'list/articles': 'articles-list',
		'list/events': 'events-list',
		'list/leisure': 'leisure-list',
		announcements: 'announcement-list',
		map: 'map',
	};
	const routeNeedleValues = Object.values(routeNeedles);
	Object.keys(routeNeedles).forEach((n, index) => {
		const value = routeNeedleValues[index];
		if (pathname.indexOf(n) !== -1) {
			routeTitle = value;
		}
	});

	if (breakingNews !== null && breakingNews.length > 0) {
		routeTitle += ' hasBreakingNews';
	}

	if (isLoading) {
		return <FullscreenLoadingIndicator />;
	}
	if (isIdle && isAtHome) {
		window.location.href = '/' + screenData?.id;
	}
	return (
		<Container
			fluid
			style={{
				backgroundColor: '#92b8bc',
			}}
		>
			<Modal show={!isAtHome && isIdle}>
				<Modal.Body>
					<Row>
						<Col>
							<b>
								Der Digitalen Dorfplatz kehrt in{' '}
								{SCREEN_TIMEOUT_COUNTDOWN - reloadTimeLeft} Sekunden zur
								Startseite zurück.
							</b>
						</Col>
					</Row>
					<Row>
						<Col>
							Berühren Sie den Bildschirm, um auf dieser Seite zu verbleiben.
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
			{!isAtHome && <HomeButtonView />}
			<div className="header vh-10">
				<Row className="header-content">
					<Col lg="3" className="header-row">
						{screenData && (
							<HeaderLogo
								type={screenData.title}
								title={screenData.name}
								image={screenData.logo}
								id={screenData.id || 1}
							></HeaderLogo>
						)}
					</Col>
					<Col lg="6" className="weather-and-time-container d-none d-lg-block">
						<Row>
							<Col lg="3">
								<Col lg="12">
									<Row className="header-column-title">
										{getCurrentDateString()}
									</Row>
									<Row>
										<span className="subtext">{currentTime} Uhr</span>
									</Row>
								</Col>
							</Col>
							<Col lg="9">
								<Row className="weather-forecast">
									<Col lg="2"></Col>
									{currentWeather && (
										<Col lg="2">
											<ForecastItem
												icon={currentWeather.icon}
												temperature={currentWeather.temperature}
												label={'Aktuell'}
											/>
										</Col>
									)}
									{weatherForecast.map((forecast: Weather, index: number) => {
										return (
											<Col
												lg="2"
												key={index}
												className={clsx({
													// eslint-disable-next-line
													['last-today-forecast']:
														index === weatherForecast.length - 1,
												})}
											>
												<ForecastItem
													icon={forecast.icon}
													temperature={forecast.temperature}
													label={forecast.duration}
												/>
											</Col>
										);
									})}
									{tomorrowWeather && (
										<Col>
											<ForecastItem
												icon={tomorrowWeather.icon}
												temperature={tomorrowWeather.temperature}
												label={tomorrowWeather.duration}
											/>
										</Col>
									)}
									<span className="weather-api-source">
										Wetter abgerufen von OpenWeathermap.org
									</span>
								</Row>
							</Col>
						</Row>
					</Col>
					{wifiSettings?.ssid && screenData?.name && (
						<WifiBox
							wifiName={wifiSettings.ssid}
							wifiPassword={wifiSettings.password}
							wifiSecurity={wifiSettings.securityMode}
							locationTitle={screenData.name}
						/>
					)}
				</Row>
			</div>

			<div className={'main ' + routeTitle}>{children}</div>
			<BreakingNewsItem news={breakingNews}></BreakingNewsItem>
			<footer className="footer vh-10">
				<Row>
					{bannerContainer.map(
						(container: DirectusBannerContainer, index: number) => {
							return (
								<Col key={index} className="banner-container-parent">
									<BannerView container={container} />
								</Col>
							);
						}
					)}
				</Row>
			</footer>
		</Container>
	);
}

export default withRouter(InteractiveShowcaseAppView);

import { createSlice } from '@reduxjs/toolkit';

export interface GlobalState {
	error: string | null;
}

export const initialState: GlobalState = {
	error: null,
};

const global = createSlice({
	name: 'global',
	initialState,
	reducers: {},
});

// eslint-disable-next-line
export const {} = global.actions;

export default global.reducer;

import React from 'react';

import { Col, Image } from 'react-bootstrap';

import {
	DirectusListItem,
	DirectusScreen,
} from '../../../../shared/models/Directus';
import { LIST_TITLE_LENGTH } from '../../../../Utils/EnvironmentSettings';
import { getThumbnail, shortText } from '../../../../Utils/Utils';

export const displayEventListItemContent = (
	// eslint-disable-next-line
	screenData: DirectusScreen,
	item: DirectusListItem
): JSX.Element => {
	const startTime = item.start.replace(/:\d+$/, '');
	const endTime = item.end ? item.end.replace(/:\d+$/, '') : null;
	const date = new Date(item.date).toLocaleDateString('de-DE', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});
	const timeText =
		startTime && endTime
			? ' · ' + startTime + ' - ' + endTime
			: !endTime && startTime
			? ' · ' + startTime
			: '';
	const imageUrl = getThumbnail(item.image);
	let text = '';
	try {
		text = item.location
			? item.location.street + ', ' + item.location.city
			: '';
	} catch {}

	const textWidth = imageUrl ? 8 : 12;
	return (
		<>
			<Col lg={textWidth}>
				<div className="list-item-text-content-container">
					<p className="list-item-meta">
						{date}
						{timeText}
					</p>
					<h4>{shortText(item.title, LIST_TITLE_LENGTH)}</h4>
					<p className="list-item-text-content">{text}</p>
				</div>
			</Col>
			{imageUrl && (
				<Col lg="4">
					<Image src={imageUrl} className="list-item-image" />
				</Col>
			)}
		</>
	);
};

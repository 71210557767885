import React, { useState } from 'react';

import './Map.scss';
import clsx from 'clsx';
import { LatLngTuple } from 'leaflet';
import { Col, ListGroup, Row } from 'react-bootstrap';
import { Map, TileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';

import { getBreakingNews } from '../../../store/BreakingNewsSlice';
import { RootState } from '../../../store/rootReducer';
import { PinCategory } from './Map.container';
interface MapViewProps {
	items: PinCategory[];
	center: LatLngTuple;
	preSelectedItemCoords: LatLngTuple | null;
	preSelectedCategory: string | null;
}

function MapView(props: MapViewProps) {
	const { items, center, preSelectedItemCoords, preSelectedCategory } = props;

	const overwrittenCenter =
		preSelectedItemCoords !== null ? preSelectedItemCoords : center;

	const [selectedCategoryItems, setSelectedCategoryItems] = useState<
		PinCategory[]
	>(
		items.filter(
			(i) => i.text === preSelectedCategory || i.identifier === 'location-pin'
		)
	);
	const isActive = (item: PinCategory) => {
		return selectedCategoryItems.filter((i) => i.text === item.text).length > 0;
	};

	const breakingNews = useSelector((state: RootState) =>
		getBreakingNews(state.breakingNews)
	);

	const categoryElements = items.map((i, _index) => {
		const isItemActive = isActive(i);
		const icon = isItemActive && i.activeIcon ? i.activeIcon : i.icon;
		return (
			<ListGroup.Item
				className={clsx({
					[i.identifier]: true,
					'is-active': isItemActive,
				})}
				style={{
					height: 100 / items.length + '%',
				}}
				onClick={() => {
					if (isItemActive) {
						setSelectedCategoryItems(
							selectedCategoryItems.filter((e) => e.identifier !== i.identifier)
						);
					} else {
						setSelectedCategoryItems([...selectedCategoryItems, i]);
					}
				}}
				key={_index}
			>
				<img alt={i.text} src={icon} />
				{i.text}
			</ListGroup.Item>
		);
	});

	return (
		<Row>
			<Col lg="10">
				<Map
					center={overwrittenCenter}
					zoom={15}
					width="100%"
					height="100%"
					className="showcase-map"
				>
					<TileLayer
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					/>
					{selectedCategoryItems?.map((c) => {
						return c.elements;
					})}
				</Map>
			</Col>
			<ListGroup
				className={clsx({
					'showcase-categories': true,
					'has-breaking-news': breakingNews.length > 0,
				})}
			>
				{categoryElements}
			</ListGroup>
		</Row>
	);
}

export default MapView;

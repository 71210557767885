import React from 'react';

import { Button, Card, Col, Row } from 'react-bootstrap';

import './ChannelBox.scss';
import { getThumbnail } from '../../../Utils/Utils';
import { DirectusChannel } from '../../models/Directus';

interface ChannelBoxProps {
	channel: DirectusChannel;
}

function AnnouncementBoxView(props: ChannelBoxProps): JSX.Element {
	const { channel } = props;
	const image = getThumbnail(channel.defaultimage);
	const link =
		'/' +
		channel.parentScreen +
		'/list/' +
		channel.category +
		'/' +
		channel.id +
		'/latest';
	return (
		<>
			<Col lg={channel.width} className="channel-box">
				<Card border="primary">
					<a href={link} className="no-highlight">
						<Card.Header>Amtl. Bekanntmachungen</Card.Header>
						<Card.Img variant="top" src={image} />
						<Card.Body>
							<Card.Title>{channel.title}</Card.Title>
							<Card.Text>
								<span className="main-text">{channel.text}</span>
							</Card.Text>
						</Card.Body>
					</a>
					<Card.Footer>
						<Row>
							<Col lg="12">
								<Button variant="primary" href={link}>
									Zur Liste
								</Button>
							</Col>
						</Row>
					</Card.Footer>
				</Card>
			</Col>
		</>
	);
}

export default AnnouncementBoxView;

import { LatLngTuple } from 'leaflet';

export interface DirectusSingleQuery {
	single: true;
}

export interface DirectusExplicitQuery {
	fields: string[];
	sort: any;
	filter: any; // the interface is only needed to distinguish between single and not single
}

export interface DirectusListItem {
	id: number;
	content: string;
	title: string;
	image: DirectusImage;
	user: DirectusUser;
	date: Date;
	start: string;
	end?: string;
	category?: DirectusLeisureOfferCategory;
	location: DirectusLocation;
	link?: string;
	municipality?: DirectusMunicipality;
}

export interface DirectusItem {
	id?: number;
	user: DirectusUser;
	modified_on: Date;
	image?: DirectusImage;
	date?: Date;
}

export interface DirectusUser {
	id: number;
	title: string | null;
}

export enum DirectusEmptyCategory {}

export enum DirectusArticleCategory {
	'latest' = 'Neueste',
	'General' = 'Allgemein',
	'youngandold' = 'Jung & Alt',
	'culture' = 'Kultur',
	'local' = 'Lokales',
	'townhall' = 'Rathaus',
	'clubs' = 'Vereine',
}

export interface DirectusArticle extends DirectusItem {
	title: string;
	content: string;
	category: string;
	date: Date;
	image?: DirectusImage;
}

export interface DirectusEvent extends DirectusItem {
	title: string;
	content: string;
	category: string;
	date: Date;
	start: string | null;
	end: string | null;
	image?: DirectusImage;
	location?: DirectusLocation;
	contact: string | null;
	link?: string;
}

export enum DirectusLocationCategory {
	'health' = 'Apotheken',
	'coworking' = 'Coworking-Spaces',
	'local' = 'Regionales',
}

export interface DirectusLocation extends DirectusItem {
	title: string;
	street: string;
	city: string;
	coords: DirectusCoords;
	category?: DirectusLocationCategory;
}

export interface DirectusEmergencyService extends DirectusItem {
	title: string;
	location: DirectusLocation;
	phone: string;
	start: string;
	end: string;
}

export enum DirectusLeisureOfferCategory {
	'proximity' = 'In der Nähe',
	'attraction' = 'Attraktionen',
	'activity' = 'Aktivitäten',
	'culture' = 'Kunst und Kultur',
	'catering' = 'Gastronomie',
	'hotels' = 'Unterkünfte',
}

export interface DirectusLeisureOffer extends DirectusItem {
	title: string;
	location: DirectusLocation;
	content: string;
	image?: DirectusImage;
	category: DirectusLeisureOfferCategory;
	contact: string;
	openingtimes: string;
	link?: string;
}

export interface DirectusCoordsItem extends DirectusItem {
	lat: number;
	lon: number;
}
export interface BreakingNews extends DirectusItem {
	text: string;
	start: Date;
	end: Date;
}

export interface DirectusBanner extends DirectusItem {
	text: string | null;
	logo: DirectusImage | null;
	container: DirectusBannerContainer[];
	logo_width: number;
}

export interface DirectusBannerContainer extends DirectusItem {
	title: string | null;
	order: number;
}

export interface DirectusCoords {
	lat: number;
	lon: number;
}

export interface IntervalOptions {
	ads: number;
	channels: number;
}

export type WifiSecurityMode = 'WEP' | 'WPA' | 'nopass';

export interface WifiSettings {
	ssid: string;
	password: string;
	securityMode: WifiSecurityMode;
}
export interface ScreenOptions {
	intervals: IntervalOptions;
	mapZoom: number;
	wifiSettings: WifiSettings | null;
}

export interface DirectusMunicipality {
	id: number;
	name: string;
	authorized_users: string[];
}

export interface DirectusScreen extends DirectusItem {
	title: string;
	name: string;
	logo: DirectusImage | null;
	backdrop: DirectusImage;
	location: DirectusLocation;
	options: ScreenOptions;
	channels: DirectusChannel[];
	municipality: DirectusMunicipality;
	forecast: any;
}

export interface DirectusScreenDataApiResponse extends DirectusScreen {
	channels: DirectusChannel[];
	bannercontainer: DirectusBannerContainer[];
}

export type DirectusChannelCategory =
	| 'articles'
	| 'announcements'
	| 'public-transport'
	| 'emergency-services'
	| 'events'
	| 'map'
	| 'leisure';

export enum DirectusChannelMap {
	'articles' = 'Aktuelles',
	'announcements' = 'Bekanntmachungen',
	'public-transport' = 'ÖPNV',
	'emergency-services' = 'Notfalldienste',
	'events' = 'Veranstaltungen',
	'map' = 'Entdecke deine Umgebung',
	'leisure' = 'Freizeit & Erholung',
}
export interface DirectusChannel extends DirectusItem {
	category: DirectusChannelCategory;
	order: number;
	width: number;
	randomize: boolean;
	limit?: number;
	defaultimage?: DirectusImage;
	title?: string;
	text?: string;
	parentScreen: number;
}

export interface DirectusImage extends DirectusItem {
	filename_download: string;
	filename_disk: string;
	folder: string | null;
}

export interface DirectusThumbnail extends DirectusItem {
	key: string;
	url: string;
	relative_url: string;
	dimension: string;
	width: number;
	height: number;
}

export interface DirectusFile extends DirectusItem {
	data: DirectusImage;
}

export interface Category extends DirectusItem {
	title: string;
	highlighted: boolean;
}

export type SlotRenderer =
	| 'news-renderer'
	| 'map-renderer'
	| 'empty-renderer'
	| 'emergency-renderer';

export interface Slot extends DirectusItem {
	category?: Category;
	index: number;
	interval: number | null;
	type: SlotRenderer;
	width: number;
}

export interface ScreenData {
	wifiname: string | null;
	title: string | null;
	type: string | null;
	image: DirectusFile | null;
	background: DirectusFile | null;
	public: boolean;
	screenposition: LatLngTuple;
}

export interface PointMarker {
	position: LatLngTuple;
	title: string;
}

export interface MapSlotItem {
	center: LatLngTuple;
	markers: PointMarker[];
}

export function getEnumDisplayText(haystack: object, needle: string) {
	// eslint-disable-next-line
	const match = Object.values(haystack).find((text: string, i: number) => {
		const name = Object.keys(haystack)[i];
		return name === needle;
	});

	return match;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import sunIcon from '../assets/weather/1.svg';
import cloudRainThunderstormMoonIcon from '../assets/weather/10.svg';
import cloudRainSunIcon from '../assets/weather/12.svg';
import cloudRainMoonIcon from '../assets/weather/13.svg';
import cloudRainIcon from '../assets/weather/16.svg';
import sunFogIcon from '../assets/weather/17.svg';
import moonFogIcon from '../assets/weather/18.svg';
import cloudSnowIcon from '../assets/weather/19.svg';
import cloudRainThunderstormIcon from '../assets/weather/23.svg';
import cloudIcon from '../assets/weather/3.svg';
import cloudSunIcon from '../assets/weather/4.svg';
import cloudMoonIcon from '../assets/weather/5.svg';
import { DirectusScreen } from '../shared/models/Directus';
import { AppThunk } from './store';

const iconMap: { [id: string]: string } = {
	'01d': sunIcon,
	'02d': cloudSunIcon,
	'03d': cloudIcon,
	'04d': cloudIcon,
	'09d': cloudRainIcon,
	'10d': cloudRainSunIcon,
	'11d': cloudRainThunderstormIcon,
	'13d': cloudSnowIcon,
	'50d': sunFogIcon,
	'01n': moonFogIcon,
	'02n': cloudMoonIcon,
	'03n': cloudIcon,
	'04n': cloudIcon,
	'09n': cloudRainIcon,
	'10n': cloudRainMoonIcon,
	'11n': cloudRainThunderstormMoonIcon,
	'13n': cloudSnowIcon,
	'50n': moonFogIcon,
};

export interface Weather {
	temperature: number;
	icon: string;
	duration: string;
}

export interface WeatherState {
	current: Weather | null;
	forecasts: Weather[];
	tomorrow: Weather | null;
	loading: boolean;
	error: Error | null;
}

export const initialState: WeatherState = {
	current: null,
	forecasts: [],
	tomorrow: null,
	loading: false,
	error: null,
};

const screen = createSlice({
	name: 'weather',
	initialState,
	reducers: {
		getWeatherStart(state) {
			state.loading = true;
			state.error = null;
		},
		getWeatherSuccess(
			state,
			action: PayloadAction<{
				current: Weather;
				forecast: Weather[];
				tomorrow: Weather;
			}>
		) {
			state.forecasts = action.payload.forecast;
			state.current = action.payload.current;
			state.tomorrow = action.payload.tomorrow;
			state.loading = false;
			state.error = null;
		},
		getWeatherFailed(state, action: PayloadAction<Error>) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

export const { getWeatherStart, getWeatherSuccess, getWeatherFailed } =
	screen.actions;

export default screen.reducer;

export const getCurrentWeather = (state: WeatherState) => state.current;

export const getTomorrowWeather = (state: WeatherState) => state.tomorrow;

export const getWeatherForecast = (state: WeatherState) => state.forecasts;

export const selectLoading = (state: WeatherState) => state.loading;

export const onGetWeather =
	(screenData: DirectusScreen): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(getWeatherStart());
			if (!screenData?.location) {
				throw new ReferenceError(
					'Position parameter missing when fetching weather data'
				);
			}

			const data = screenData.forecast;

			const current: any = data.current;
			const tomorrow: any = data.daily[1];
			const currentDate = new Date();
			const tomorrowDate = new Date(currentDate);
			tomorrowDate.setDate(currentDate.getDate() + 1);
			const currentWeather = {
				temperature: Math.floor(current.temp),
				duration: currentDate.toLocaleDateString('de-DE', {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
				}),
				icon: iconMap[current.weather[0].icon],
			};
			const tomorrowWeather = {
				temperature: Math.floor(tomorrow.temp.max),
				duration: tomorrowDate.toLocaleDateString('de-DE', {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
				}),
				icon: iconMap[tomorrow.weather[0].icon],
			};

			const raw_forecast: any[] = data.hourly;
			const groupedForecast: { [key: string]: Weather } = {};
			raw_forecast.forEach((raw: any, key: number) => {
				const date = new Date(raw.dt * 1000);
				const hour = date.getHours();
				let daytimeName = '';
				if (hour >= 6 && hour <= 12) {
					daytimeName = 'Morgens';
				}
				if (hour >= 12 && hour <= 17) {
					daytimeName = 'Mittags';
				}
				if (hour >= 17 && hour <= 22) {
					daytimeName = 'Abends';
				}
				if (hour >= 22 || hour <= 6) {
					daytimeName = 'Nachts';
				}
				const lastWeather = groupedForecast[daytimeName];
				const weatherTemp = Math.floor(raw.temp);
				if (!lastWeather || lastWeather.temperature < weatherTemp) {
					groupedForecast[daytimeName] = {
						temperature: weatherTemp,
						duration: daytimeName,
						icon: iconMap[raw.weather[0].icon],
					};
				}
			});
			// flatten weather againk
			const flatForecast = [];
			for (const key in groupedForecast) {
				if (flatForecast.length === 3) {
					break;
				}
				const weather = groupedForecast[key];
				flatForecast.push(weather);
			}
			dispatch(
				getWeatherSuccess({
					current: currentWeather,
					forecast: flatForecast,
					tomorrow: tomorrowWeather,
				})
			);
		} catch (error) {
			dispatch(getWeatherFailed(error));
		}
	};

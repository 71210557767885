import { QueryMany } from '@directus/sdk';
import React from 'react';

import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { useDirectus } from '../hooks/directus';
import { DirectusItem } from '../shared/models/Directus';
import DetailView from '../shared/views/detailview/Detail.view';
import LoadingIndicator from '../shared/views/LoadingIndicator/LoadingIndicator.view';
import { RootState } from '../store/rootReducer';
import { selectScreenData } from '../store/ScreenSlice';

interface EventRouteParams {
	channelId: string;
	itemId: string;
	screen: string;
}

interface Props extends RouteComponentProps<EventRouteParams> {}

export const Event = (props: Props) => {
	const { channelId, itemId, screen } = props.match.params;

	const screenData = useSelector((state: RootState) =>
		selectScreenData(state.screen)
	);

	const [result, error, state] = useDirectus(async (client) => {
		const query: QueryMany<DirectusItem> = {
			fields: [
				'title',
				'content',
				'image.*',
				'id',
				'date',
				'start',
				'end',
				'location.*',
				'user.title',
				'contact',
				'link',
			],
			filter: {
				date: {
					_gte: '$NOW',
				},
			},
			sort:  ["-date"]
		};
		return await client.items<'events', DirectusItem>('events').readMany(query);
	});

	if (state === 'success' && result && result.data && Array.isArray(result.data)) {
		const rawItems = result.data as DirectusItem[];
		const item = rawItems.find((i: DirectusItem) => {
			return i.id === parseInt(itemId);
		});
		if (!item) {
			return <span>Not found</span>;
		}
		return (
			<DetailView
				screenId={screen}
				item={item}
				channelId={parseInt(channelId)}
				renderMode="event"
				allItems={rawItems}
			/>
		);
	}

	if (state === 'success' && !result) {
		throw Error(error?.message);
	}
	return (
		<div className="page-spinner">
			<LoadingIndicator />
		</div>
	);
};

import React from 'react';

import { Route, Switch, withRouter } from 'react-router-dom';

import { Detail } from './Detail.container';

export const DETAIL_URL = '/:screen/articles/:channelId/:itemId';

function DetailRouting() {
	return (
		<>
			<Switch>
				<Route path={DETAIL_URL} component={Detail} />
			</Switch>
		</>
	);
}

export default withRouter(DetailRouting);

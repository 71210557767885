import React from 'react';

import { Col, Row } from 'react-bootstrap';

import './ListItem.scss';

import arrow from '../../../assets/arrow_right.svg';

interface Props {
	screenId: number;
	collectionName: string;
	channelId: number;
	categoryName: string;
	page: number;
}

function NextPageItem(props: Props) {
	const { screenId, collectionName, channelId, categoryName, page } = props;
	return (
		<a
			href={
				'/' +
				screenId +
				'/list/' +
				collectionName +
				'/' +
				channelId +
				'/' +
				categoryName +
				'/' +
				page
			}
			className="list-item next-page-item"
		>
			<Row>
				<Col lg="12">
					Nächste Seite
					<img alt="next-page-icon" src={arrow} />
				</Col>
			</Row>
		</a>
	);
}

export default NextPageItem;

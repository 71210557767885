import React from 'react';

import './HomeButton.scss';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import backIcon from '../../../assets/arrow_back.svg';
import homeIcon from '../../../assets/home.svg';
import { RootState } from '../../../store/rootReducer';
import { selectLoading, selectScreenData } from '../../../store/ScreenSlice';

function HomeButtonView() {
	const screenData = useSelector((state: RootState) =>
		selectScreenData(state.screen)
	);
	const isLoading = useSelector((state: RootState) =>
		selectLoading(state.screen)
	);

	if (isLoading) {
		return <></>;
	}
	const showBackButton =
		document.referrer !== document.location.origin + '/' + screenData?.id;
	return (
		<>
			<a href={'/' + screenData?.id} className="d-none d-lg-block">
				<img
					alt="home-button-icon"
					className={clsx({
						'nav-button home-button': true,
						'no-back-button': !showBackButton,
					})}
					src={homeIcon}
				/>
			</a>

			{showBackButton && (
				<a href="/" className="d-none d-lg-block">
					<img
						alt="home-button-icon"
						className="nav-button back-button"
						src={backIcon}
						onClick={(e) => {
							e.preventDefault();

							window.history.back();
						}}
					/>
				</a>
			)}
		</>
	);
}
export default HomeButtonView;

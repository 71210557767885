import { QueryMany, QueryOne } from '@directus/sdk';
import React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import { useDirectus } from '../hooks/directus';
import {
	DirectusItem,
	DirectusLeisureOffer,
	DirectusLeisureOfferCategory,
	DirectusSingleQuery,
} from '../shared/models/Directus';
import DetailView from '../shared/views/detailview/Detail.view';
import LoadingIndicator from '../shared/views/LoadingIndicator/LoadingIndicator.view';

interface LeisureDetailParams {
	channelId: string;
	itemId: string;
	screen: string;
}

interface Props extends RouteComponentProps<LeisureDetailParams> {}

export const LeisureDetail = (props: Props) => {
	const { channelId, itemId, screen } = props.match.params;

	const [result, error, state] = useDirectus(async (client) => {
		const query: QueryMany<DirectusLeisureOffer> = {
			fields: [
				'title',
				'content',
				'id',
				'location.*',
				'category',
				'contact',
				'openingtimes',
				'link',
				'image.*',
			],
			filter: {
				id: {
					_eq: itemId,
				},
			},
		};

		return await client.items<'leisure', DirectusLeisureOffer>('leisure').readMany(query);
	});

	const sidebarContents = Object.values(DirectusLeisureOfferCategory).map(
		(e: string, i: number) => {
			return (
				<a
					key={i}
					href={'/list/channel/' + Object.keys(DirectusLeisureOfferCategory)[i]}
				>
					{e}
				</a>
			);
		}
	);

	if (state === 'success' && result && result.data &&  Array.isArray(result.data)) {
		const rawItems = result.data as DirectusItem[];
		const item = rawItems.find((i: DirectusItem) => {
			return i.id === parseInt(itemId);
		})
		if (!item) {
			return <span>Not found</span>;
		}
		return (
			<DetailView
				screenId={screen}
				item={item}
				channelId={parseInt(channelId)}
				renderMode="leisure"
				sidebarContents={sidebarContents}
			/>
		);
	}

	if (state === 'success' && !result) {
		throw Error(error?.message);
	}
	return (
		<div className="page-spinner">
			<LoadingIndicator />
		</div>
	);
};

import React from 'react';

import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../store/rootReducer';
import { selectScreenData } from '../../../../store/ScreenSlice';
import { DirectusEmergencyService } from '../../../models/Directus';
import { DisplayProps } from '../ChannelBox.view';

function EmergencyServiceView(props: DisplayProps) {
	const { item } = props;
	const screenData = useSelector((state: RootState) =>
		selectScreenData(state.screen)
	);
	const emergencyService = item as DirectusEmergencyService;
	return (
		<a
			className="emergency-channel-link"
			href={'/' + screenData?.id + '/map/' + emergencyService.location.id}
		>
			<Card.Header>Notdienste</Card.Header>
			<Card.Body className="emergency-services-body">
				<Card.Title>Nächste Notapotheke</Card.Title>
				<Card.Text as="span">
					<span>{emergencyService.location.title}</span>
				</Card.Text>
				<Card.Text as="p">
					<span>{emergencyService.location.street}</span>
					<br />
					<span>{emergencyService.location.city}</span>
				</Card.Text>
				<Card.Text as="p">
					<span>Tel.: {emergencyService.phone}</span>
				</Card.Text>
				<Card.Title className="emergency-call-title">
					Ärztlicher Bereitschaftsdienst
				</Card.Title>
				<Card.Text as="p">
					<span>Tel.: 116117</span>
				</Card.Text>
			</Card.Body>
		</a>
	);
}

export default EmergencyServiceView;

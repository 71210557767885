import React from 'react';

import QRCode from 'qrcode.react';
import { Button, Col, Row } from 'react-bootstrap';

import {
	DirectusLocation,
	DirectusScreen,
} from '../../../../shared/models/Directus';
import { LIST_TITLE_LENGTH } from '../../../../Utils/EnvironmentSettings';
import { getDistanceText, shortText } from '../../../../Utils/Utils';

export const displayGenericListItemContent = (
	screenData: DirectusScreen,
	item: DirectusLocation,
	href?: string,
	addQrCode?: boolean
): JSX.Element => {
	const { street, city, coords, title } = item;
	const screenLat = screenData?.location.coords.lat || 1;
	const screenLon = screenData?.location.coords.lon || 1;
	let text = '';
	try {
		text = item ? street + ', ' + city : '';
	} catch {}

	return (
		<>
			<Col lg="12">
				<div className="list-item-text-content-container">
					<h4>
						{shortText(title, LIST_TITLE_LENGTH)}{' '}
						{item.coords &&
							getDistanceText([screenLat, screenLon], [coords.lat, coords.lon])}
					</h4>
					<p className="list-item-text-content">{text}</p>
				</div>
			</Col>
			{href && (
				<Col lg="12">
					<a href={href}>
						<Button className="map-channel-button" variant="primary">
							Mehr erfahren
						</Button>
					</a>
				</Col>
			)}

			{addQrCode && (
				<Col className="popup-qrcode-parent">
					<Row>
						<Col>Navigation starten</Col>
					</Row>
					<Row>
						<Col className="popup-qrcode">
							<QRCode
								value={`https://map.project-osrm.org/?z=15&center=${screenLat}%2C${screenLon}&loc=${screenLat}%2C${screenLon}&loc=${coords.lat}%2C${coords.lon}&hl=de&alt=0&srv=2`}
								size={80}
							/>
						</Col>
					</Row>
				</Col>
			)}
		</>
	);
};

import { configureStore, ThunkAction } from '@reduxjs/toolkit';
import { Action } from 'redux';

import rootReducer, { RootState } from './rootReducer';

export const store = configureStore({
	reducer: rootReducer,
	devTools: process.env.NODE_ENV !== 'production',
});

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export type AppDispatch = typeof store.dispatch;

export default store;

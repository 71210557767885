export const STAGE = process.env.REACT_APP_STAGE as Stage;
export const BACKEND = process.env.REACT_APP_BACKEND as string;

export type Stage = 'local' | 'dev' | 'stage' | 'prod';

export const isLocal = (): boolean => STAGE === 'local';

export const ASSETS_ROOT = process.env.REACT_APP_BACKEND + 'assets/';

export const THUMBNAIL_WIDTH = 500;

export const MAX_TITLE_LENGTH = 50;

export const MAX_TEXT_LENGTH = 200;

export const LIST_TITLE_LENGTH = 40;

export const LIST_TEXT_LENGHT = 75;

export const EVENT_SIDEBAR_COUNT = 3;

import React, { ReactNode } from 'react';

import L, { LatLngTuple } from 'leaflet';
import QRCode from 'qrcode.react';
import { Col, Row } from 'react-bootstrap';
import { Marker, Popup, TileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import activityActiveSvg from '../../../assets/pins/activity-active.svg';
import activitySvg from '../../../assets/pins/activity.svg';
import attractionActiveSvg from '../../../assets/pins/attraction-active.svg';
import attractionSvg from '../../../assets/pins/attraction.svg';
import cateringActiveSvg from '../../../assets/pins/catering-active.svg';
import cateringSvg from '../../../assets/pins/catering.svg';
import coworkingActiveSvg from '../../../assets/pins/coworking-active.svg';
import coworkingSvg from '../../../assets/pins/coworking.svg';
import cultureActiveSvg from '../../../assets/pins/culture-active.svg';
import cultureSvg from '../../../assets/pins/culture.svg';
import cyclingActiveSvg from '../../../assets/pins/cycling-active.svg';
import cyclingSvg from '../../../assets/pins/cycling.svg';
import healthActiveSvg from '../../../assets/pins/health-active.svg';
import healthSvg from '../../../assets/pins/health.svg';
import hikingActiveSvg from '../../../assets/pins/hiking-active.svg';
import hikingSvg from '../../../assets/pins/hiking.svg';
import hotelActiveSvg from '../../../assets/pins/hotel-active.svg';
import hotelSvg from '../../../assets/pins/hotel.svg';
import localActiveSvg from '../../../assets/pins/local-active.svg';
import localSvg from '../../../assets/pins/local.svg';
import locationActiveSvg from '../../../assets/pins/location-active.svg';
import locationSvg from '../../../assets/pins/location.svg';
import { useDirectus } from '../../../hooks/directus';
import { displayGenericListItemContent } from '../../../modules/list/listItem/displays/Generic.view';
import { RootState } from '../../../store/rootReducer';
import { selectChannels, selectScreenData } from '../../../store/ScreenSlice';
import {
	DirectusChannel,
	DirectusLeisureOffer,
	DirectusLeisureOfferCategory,
	DirectusLocation,
	DirectusLocationCategory,
} from '../../models/Directus';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator.view';
import MapView from './Map.view';

const pinMap: { [id: string]: string } = {
	activityActive: activityActiveSvg,
	activity: activitySvg,
	healthActive: healthActiveSvg,
	health: healthSvg,
	attractionActive: attractionActiveSvg,
	attraction: attractionSvg,
	coworkingActive: coworkingActiveSvg,
	coworking: coworkingSvg,
	cateringActive: cateringActiveSvg,
	catering: cateringSvg,
	cultureActive: cultureActiveSvg,
	culture: cultureSvg,
	cyclingActive: cyclingActiveSvg,
	cycling: cyclingSvg,
	localActive: localActiveSvg,
	local: localSvg,
	locationActive: locationActiveSvg,
	location: locationSvg,
	hotelsActive: hotelActiveSvg,
	hotels: hotelSvg,
	hikingActive: hikingActiveSvg,
	hiking: hikingSvg,
};

export interface PinItem {
	location: DirectusLocation;
	title: string;
	id: number;
}
export interface PinCategory {
	icon: string;
	activeIcon?: string;
	identifier: string;
	text: string;
	items: PinItem[];
	elements?: ReactNode;
	channel?: DirectusChannel;
}

interface MapRouteParams {
	screen: string;
	placeId: string;
}

interface Props extends RouteComponentProps<MapRouteParams> {}

const hiddenVirtualCategories = ['proximity'];

export function Map(props: Props) {
	const items: PinCategory[] = [];

	const screenData = useSelector((state: RootState) =>
		selectScreenData(state.screen)
	);

	const channels = useSelector((state: RootState) =>
		selectChannels(state.screen)
	);

	// eslint-disable-next-line
	const query: any = {
		fields: [
			'id',
			'title',
			'content',
			'id',
			'image.*',
			'owner.title',
			'location.*',
			'category',
		],
		filter: {},
		sort: 'title',
	};

	// eslint-disable-next-line
	const pinQuery: any = {
		fields: ['id', 'title', 'coords', 'category', 'city', 'street', 'postal'],
		filter: {
			category: {
				_nnull: true,
			},
			coords: {
				_nnull: true,
			},
		},
		sort: 'title',
	};

	// eslint-disable-next-line
	const [result, error, state] = useDirectus(async (client) => {
		return await client
			.items<'leisure', DirectusLeisureOffer>('leisure')
			.readMany(query);
	});

	// eslint-disable-next-line
	const [pinResult, pinError, pinState] = useDirectus(async (client) => {
		return await client
			.items<'locations', DirectusLocation>('locations')
			.readMany(pinQuery);
	});
	let preSelectedCategory = null;
	let preSelectedItemCoords: LatLngTuple | null = null;

	if (
		state === 'success' &&
		result?.data &&
		Array.isArray(result.data) &&
		pinState === 'success' &&
		pinResult &&
		Array.isArray(pinResult.data)
	) {
		const rawItems = result.data;
		// Create sidebar items: Leisure offers
		Object.values(DirectusLeisureOfferCategory).forEach(
			(e: string, i: number) => {
				const identifier = Object.keys(DirectusLeisureOfferCategory)[i];
				const icon = pinMap[identifier];
				const activeIcon = pinMap[identifier + 'Active'];
				if (hiddenVirtualCategories.indexOf(identifier) !== -1) {
					return;
				}
				const channel = channels.find((c) => c.category === 'leisure');
				const sidebarItem: PinCategory = {
					icon,
					activeIcon,
					identifier,
					text: Object.values(DirectusLeisureOfferCategory)[i],
					items: [],
					channel,
				};
				if (rawItems) {
					sidebarItem.elements = rawItems
						.filter((i) => i.category === identifier)
						.filter((i) => i && i.location)
						.map((i, index) => {
							const isItemPreselected =
								i?.location?.id === parseInt(props.match.params.placeId);
							const lat = i?.location?.coords?.lat || -1;
							const lon = i?.location?.coords?.lon || -1;

							const coords: LatLngTuple = [lat, lon];
							if (isItemPreselected) {
								preSelectedCategory = sidebarItem.text;
								preSelectedItemCoords = coords;
							}
							const category = i.category || 'Keine Kategorie';
							const locationIcon = L.icon({
								iconUrl: pinMap[category],
								iconSize: [50, 82],
								iconAnchor: [24, 82],
							});
							const href =
								'/' + screenData?.id + '/leisure/' + channel?.id + '/' + i.id;
							return (
								<Marker key={index} position={coords} icon={locationIcon}>
									<Popup interactive={true} direction="right" opacity={1}>
										<Row>
											{screenData &&
												i &&
												displayGenericListItemContent(
													screenData,
													i.location as DirectusLocation,
													href,
													true
												)}
										</Row>
									</Popup>
								</Marker>
							);
						});
				}

				items.push(sidebarItem);
			}
		);

		// Add constant elements
		const cyclingLayer: PinCategory = {
			icon: pinMap['cycling'],
			activeIcon: pinMap['cyclingActive'],
			identifier: 'cycling-layer',
			text: 'Radfahrwege',
			items: [],
			elements: (
				<TileLayer
					url="https://tile.waymarkedtrails.org/cycling/{z}/{x}/{y}.png"
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				/>
			),
		};

		items.push(cyclingLayer);
		const hikingLayer: PinCategory = {
			icon: pinMap['hiking'],
			activeIcon: pinMap['hikingActive'],
			identifier: 'hiking-layer',
			text: 'Wanderwege',
			items: [],
			elements: (
				<TileLayer
					url="https://tile.waymarkedtrails.org/hiking/{z}/{x}/{y}.png"
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				/>
			),
		};
		items.push(hikingLayer);
		const screenIcon = L.icon({
			iconUrl: pinMap['location'],
			iconSize: [50, 82],
			iconAnchor: [24, 82],
		});

		const lat = screenData?.location.coords.lat || 1;
		const lon = screenData?.location.coords.lon || 1;

		const screenLocation: PinCategory = {
			icon: pinMap['location'],
			activeIcon: pinMap['locationActive'],
			identifier: 'location-pin',
			text: 'Standort Bildschirm',
			items: [],
			elements: (
				<Marker position={[lat, lon]} icon={screenIcon}>
					<Popup direction="right" opacity={1}>
						<Row>
							<Col>
								<b>Standort Bildschirm</b>
							</Col>
						</Row>
					</Popup>
				</Marker>
			),
		};
		items.push(screenLocation);

		// Add generic pin elements

		Object.values(DirectusLocationCategory).forEach((e: string, i: number) => {
			const identifier = Object.keys(DirectusLocationCategory)[i];
			const icon = pinMap[identifier];
			const activeIcon = pinMap[identifier + 'Active'];
			const sidebarItem: PinCategory = {
				icon,
				activeIcon,
				identifier: Object.keys(DirectusLocationCategory)[i],
				text: Object.values(DirectusLocationCategory)[i],
				items: [],
			};
			// map offers to sidebar Items
			if (pinResult?.data && Array.isArray(pinResult?.data)) {
				console.log(pinResult.data);
				sidebarItem.elements = pinResult?.data
					.filter((i) => i.coords !== null)
					.filter((i) => i.category === sidebarItem.identifier)
					.map((i, index) => {
						const locationIcon = L.icon({
							iconUrl: icon,
							iconSize: [50, 82],
							iconAnchor: [24, 82],
						});
						const isItemPreselected =
							i.id === parseInt(props.match.params.placeId);
						const pinLat = i?.coords?.lat || -1;
						const pinLon = i?.coords?.lon || -1;
						if (isItemPreselected) {
							preSelectedCategory = sidebarItem.text;
							preSelectedItemCoords = [pinLat, pinLon];
						}
						if (
							i?.category?.toString() === sidebarItem.identifier &&
							i.coords
						) {
							// leisure display will be reused
							return (
								<Marker
									key={index}
									position={[pinLat, pinLon]}
									icon={locationIcon}
								>
									<Popup direction="right" opacity={1}>
										<Row>
											{isItemPreselected}
											{screenData &&
												i &&
												displayGenericListItemContent(
													screenData,
													i as DirectusLocation
												)}
										</Row>
										<Row>
											<Col className="popup-qrcode-parent">
												<Row>
													<Col>Navigation starten</Col>
												</Row>
												<Row>
													<Col className="popup-qrcode">
														<QRCode
															value={`https://map.project-osrm.org/?z=15&center=${lat}%2C${lon}&loc=${lat}%2C${lon}&loc=${pinLat}%2C${pinLon}&hl=de&alt=0&srv=2`}
															size={80}
														/>
													</Col>
												</Row>
											</Col>
										</Row>
									</Popup>
								</Marker>
							);
						} else {
							return <></>;
						}
					});
			}
			items.push(sidebarItem);
		});
		const sortedItems = items.sort((a, b) => a.text.localeCompare(b.text));
		return (
			<MapView
				items={sortedItems}
				center={[
					screenData?.location.coords.lat || 0,
					screenData?.location.coords.lon || 0,
				]}
				preSelectedCategory={preSelectedCategory}
				preSelectedItemCoords={preSelectedItemCoords}
			/>
		);
	} else {
		return (
			<div className="page-spinner">
				<LoadingIndicator />
			</div>
		);
	}
}

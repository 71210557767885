import React from 'react';

import { Route, Switch, withRouter } from 'react-router-dom';

import { Map } from './Map.container';

export const MAP_URL = '/:screen/map/:placeId?';

function StartRouting() {
	return (
		<Switch>
			<Route path={MAP_URL} component={Map} />
		</Switch>
	);
}

export default withRouter(StartRouting);

import { LatLngTuple } from 'leaflet';

import { DirectusImage, DirectusListItem } from '../shared/models/Directus';
import {
	ASSETS_ROOT,
	MAX_TEXT_LENGTH,
	MAX_TITLE_LENGTH,
	THUMBNAIL_WIDTH,
} from './EnvironmentSettings';

export function getCurrentDateString() {
	const today = new Date();
	const rawDate = today.getDate();
	const dd = rawDate;
	const rawMonth = today.getMonth() + 1; // January is 0!
	const mm = rawMonth;
	const yyyy = today.getFullYear();

	const dateString =
		(dd < 10 ? '0' + dd : dd) + '.' + (mm < 10 ? '0' + mm : mm) + '.' + yyyy;

	const day = today.toLocaleDateString('de-de', { weekday: 'short' });
	return day + '. ' + dateString;
}

export function getCurrentTimeString() {
	const today = new Date();
	const timeString =
		(today.getHours() < 10 ? '0' + today.getHours() : today.getHours()) +
		':' +
		(today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes());
	return timeString;
}

export const chunkArray = (array: DirectusListItem[], size: number) => {
	const result = [];
	for (const value of array) {
		const lastArray = result[result.length - 1];
		if (!lastArray || lastArray.length === size) {
			result.push([value]);
		} else {
			lastArray.push(value);
		}
	}
	return result;
};

export const distance = (c1: LatLngTuple, c2: LatLngTuple) => {
	const R = 6371e3; // metres
	const φ1 = (c1[0] * Math.PI) / 180; // φ, λ in radians
	const φ2 = (c2[0] * Math.PI) / 180;
	const Δφ = ((c1[0] - c2[0]) * Math.PI) / 180;
	const Δλ = ((c2[1] - c1[1]) * Math.PI) / 180;

	const a =
		Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
		Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

	const d = R * c; // in metres
	const km = d / 1000;
	return km;
};

export const getDistanceText = (c1: LatLngTuple, c2: LatLngTuple) => {
	const dist = distance(c1, c2);

	return ' (in ' + dist.toFixed(2) + 'km)';
};

export const getImageUrl = (image?: DirectusImage | null) => {
	if (!image) {
		return undefined;
	}
	const { folder, id } = image;
	if (!folder) {
		return ASSETS_ROOT + '/' + id;
	} else {
		return ASSETS_ROOT + '/' + folder + '/' + id;
	}
};

export const getThumbnail = (
	image?: DirectusImage | null,
	width = THUMBNAIL_WIDTH
) => {
	if (!image) {
		return undefined;
	}
	return getImageUrl(image) + '?width=' + width + '&quality=80&fit=cover';
};

export const shuffleArray = (array: any[]) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		const temp = array[i];
		array[i] = array[j];
		array[j] = temp;
	}
	return array;
};

export const shortTitle = (title: string) => {
	return title.length > MAX_TITLE_LENGTH
		? title.substring(0, MAX_TITLE_LENGTH) + '...'
		: title;
};

export const shortText = (text: string, length: number = MAX_TEXT_LENGTH) => {
	if (!text) {
		return '';
	}
	return text.length > length ? text.substring(0, length) + '...' : text;
};

import React from 'react';

import './ChannelBox.scss';
import L, { LatLngTuple } from 'leaflet';
import { Button, Card, Col } from 'react-bootstrap';
import { Map, Marker, TileLayer, Tooltip } from 'react-leaflet';

import markerIcon from '../../../assets/marker-icon.png';
import { DirectusChannel, DirectusCoords } from '../../models/Directus';

interface ChannelBoxProps {
	coords: DirectusCoords;
	channel: DirectusChannel;
	mapZoom: number;
}

function MapBoxView(props: ChannelBoxProps): JSX.Element {
	const { channel, coords, mapZoom } = props;
	const latLngTuple: LatLngTuple = [coords.lat, coords.lon];
	const locationIcon = L.icon({
		iconUrl: markerIcon,
		iconSize: [25, 41],
		iconAnchor: [12, 41],
	});
	const link = '/' + channel.parentScreen + '/map/';
	return (
		<>
			<Col lg={channel.width} className="channel-box">
				<Card border="primary" className="map-channel">
					<a href={link} className="no-highlight">
						<Card.Header>Erkunde die Umgebung</Card.Header>
						<Card.Body>
							<Map
								center={latLngTuple}
								zoom={mapZoom}
								className="channel-box-map"
							>
								<TileLayer
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
									attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
								/>
								<Marker position={latLngTuple} icon={locationIcon}>
									<Tooltip direction="right" opacity={1} permanent>
										<span>Mein Standort</span>
									</Tooltip>
								</Marker>
							</Map>
						</Card.Body>
					</a>
					<Card.Footer>
						<Button
							className="map-channel-button"
							variant="primary"
							href={link}
						>
							Zur Karte
						</Button>
					</Card.Footer>
				</Card>
			</Col>
		</>
	);
}

export default MapBoxView;

import React from 'react';

import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { getBreakingNews } from '../../../store/BreakingNewsSlice';
import { RootState } from '../../../store/rootReducer';
import './SideBar.scss';
export interface SideBarProps {
	title: string;
	sideBarContents: any[];
	format: (e: any, i: number, currentlySelected?: string) => JSX.Element;
	currentlySelected?: string;
	bottomLink?: JSX.Element;
}

export default function SideBarView(props: SideBarProps) {
	const {
		sideBarContents,
		title,
		format,
		bottomLink,
		currentlySelected,
	} = props;

	const breakingNews = useSelector((state: RootState) =>
		getBreakingNews(state.breakingNews)
	);

	return (
		<div className="sidebar">
			<span className="sidebar-header">{title}</span>
			<div
				className={clsx({
					'sidebar-inner': true,
					'has-breaking-news': breakingNews.length > 0,
				})}
			>
				{sideBarContents.map((e: any, i: number) => {
					return <div key={i}>{format(e, i, currentlySelected)}</div>;
				})}
				{bottomLink && bottomLink}
			</div>
		</div>
	);
}

import React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import { useDirectus } from '../hooks/directus';
import {
	DirectusArticle,
	DirectusArticleCategory,
	DirectusItem,
	DirectusSingleQuery,
} from '../shared/models/Directus';
import DetailView from '../shared/views/detailview/Detail.view';
import LoadingIndicator from '../shared/views/LoadingIndicator/LoadingIndicator.view';

interface DetailRouteParams {
	screen: string;
	channelId: string;
	itemId: string;
}

interface Props extends RouteComponentProps<DetailRouteParams> {}

export const Detail = (props: Props) => {
	const { channelId, itemId, screen } = props.match.params;
	const [result, error, state] = useDirectus(async (client) => {
		return await client.items('articles').readMany({
			filter: {
				id: {
					_eq: itemId,
				}
			},
		});
	});

	const sidebarContents = Object.values(DirectusArticleCategory).map(
		(e: string, i: number) => {
			return (
				<a
					key={i}
					href={
						screen + '/list/channel/' + Object.keys(DirectusArticleCategory)[i]
					}
				>
					{e}
				</a>
			);
		}
	);
	if (state === 'success' && result && result.data && Array.isArray(result.data)) {
		const item = result.data.pop() as DirectusItem;
		console.log("DETAIl", item)
		return (
			<DetailView
				screenId={screen}
				item={item}
				channelId={parseInt(channelId)}
				renderMode="detail"
				sidebarContents={sidebarContents}
			/>
		);
	}

	if (state === 'success' && !result) {
		throw Error(error?.message);
	}
	return <LoadingIndicator />;
};

import React from 'react';

import IdleTimer from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { Route, Switch, useParams, withRouter } from 'react-router-dom';

import DetailRoutingContainer, {
	DETAIL_URL,
} from './detail/DetailRouting.container';
import EventRoutingContainer, {
	EVENT_URL,
} from './event/EventRouting.container';
import LeisureRoutingContainer, {
	LEISURE_URL,
} from './leisure/LeisureRouting.container';
import ListRoutingContainer, {
	LIST_URL,
} from './modules/list/ListRouting.container';
import StartRoutingContainer, {
	START_URL,
} from './modules/start/StartRouting.container';
import MapRoutingContainer, {
	MAP_URL,
} from './shared/views/map/MapRouting.container';
import ShowcaseAppView from './Showcase.view';
import {
	onAction,
	onGetScreenData,
	onIdle,
	onReloadTimeLeftChange,
} from './store/ScreenSlice';

interface StartRouteParams {
	screen: string;
}

export const SCREEN_TIMEOUT = 1000 * 60 * 15; /* 15 minutes */
export const SCREEN_TIMEOUT_COUNTDOWN = 60; /* 60 second timeout */

function ShowcaseContainer(): JSX.Element {
	const dispatch = useDispatch();
	const { screen } = useParams<StartRouteParams>();
	dispatch(onGetScreenData(parseInt(screen)));
	let intervalId: NodeJS.Timeout;
	const handleIdleTimeout = function () {
		const ua = navigator.userAgent;
		const isMobile = /Android|webOS|iPhone|iPad|iPod/i.test(ua);
		if (isMobile) {
			/* if the user is on a mobile version, do not do any redirects */
			return;
		}
		if (window.location.pathname === '/' + screen) {
			window.location.href = '/' + screen;
		}
		dispatch(onIdle());
		let i = 1;
		clearInterval(intervalId);
		dispatch(onReloadTimeLeftChange(i));
		intervalId = setInterval(() => {
			if (i === SCREEN_TIMEOUT_COUNTDOWN - 1) {
				window.location.href = '/' + screen;
			}
			i++;
			dispatch(onReloadTimeLeftChange(i));
		}, 1000);
	};
	const handleAction = function () {
		dispatch(onAction());
		clearInterval(intervalId);
	};
	return (
		<IdleTimer
			timeout={SCREEN_TIMEOUT}
			onIdle={handleIdleTimeout}
			onAction={handleAction}
		>
			<ShowcaseAppView>
				<Switch>
					<Route exact path={START_URL}>
						<StartRoutingContainer />
					</Route>
				</Switch>
				<Switch>
					<Route path={EVENT_URL} component={EventRoutingContainer}></Route>
				</Switch>

				<Switch>
					<Route path={DETAIL_URL} component={DetailRoutingContainer}></Route>
				</Switch>
				<Switch>
					<Route path={LEISURE_URL} component={LeisureRoutingContainer}></Route>
				</Switch>
				<Switch>
					<Route path={LIST_URL} component={ListRoutingContainer}></Route>
				</Switch>
				<Switch>
					<Route path={MAP_URL} component={MapRoutingContainer}></Route>
				</Switch>
			</ShowcaseAppView>
		</IdleTimer>
	);
}

export default withRouter(ShowcaseContainer);

import React from 'react';

import { Row } from 'react-bootstrap';

import './ForecastItem.scss';

interface ForecastIemProps {
	icon: string;
	temperature: number;
	label: string;
}

function ForecastItem(props: ForecastIemProps) {
	const { icon, temperature, label } = props;
	return (
		<>
			<Row className="header-column-title">{label}</Row>
			<Row>
				<span className="subtext">{temperature}°C</span>
				<img className="header-weather-icon" alt={icon} src={icon} />
			</Row>
		</>
	);
}

export default ForecastItem;

import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store/rootReducer';
import { selectChannels } from '../../../store/ScreenSlice';
import { getImageUrl } from '../../../Utils/Utils';
import {
	DirectusArticle,
	DirectusEvent,
	DirectusItem,
	DirectusLeisureOffer,
} from '../../models/Directus';
import ArticleDetailView from './contents/ArticleDetail.view';
import EventDetailView from './contents/EventDetail.view';
import LeisureDetailView from './contents/LeisureDetail.view';
import '../../views/detailview/Detail.scss';

type renderMode = 'detail' | 'leisure' | 'event';
interface Props {
	item: DirectusItem;
	allItems?: DirectusItem[];
	channelId: number;
	renderMode: renderMode;
	sidebarContents?: JSX.Element[];
	screenId: string;
}

export default function DetailView(props: Props) {
	const { item, channelId, renderMode, allItems } = props;
	let content: JSX.Element = <></>;

	const channels = useSelector((state: RootState) =>
		selectChannels(state.screen)
	);
	const channel = channels.find((c) => c.id === channelId);
	const channelName = channel?.category;

	if (renderMode === 'detail') {
		content = (
			<ArticleDetailView
				item={item as DirectusArticle}
				channelName={channelName || ''}
				channel={channel}
			/>
		);
	} else if (renderMode === 'event') {
		content = (
			<EventDetailView
				item={item as DirectusEvent}
				channel={channel}
				allItems={allItems as DirectusEvent[]}
			/>
		);
	} else {
		content = (
			<LeisureDetailView
				item={item as DirectusLeisureOffer}
				channel={channel}
			/>
		);
	}

	const imageUrl = getImageUrl(item.image);
	return (
		<>
			{item.image && (
				<div
					className="detail-backdrop"
					style={{
						background: 'url(' + imageUrl + ')',
					}}
				></div>
			)}
			<Row className="detail-page-parent">
				<Col lg="8">
					<Row className="detail-page-body">{content}</Row>
				</Col>
			</Row>
		</>
	);
}

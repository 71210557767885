import React from 'react';

import QRCode from 'qrcode.react';
import { Badge, Col, Row } from 'react-bootstrap';

import {
	DirectusChannel,
	DirectusLeisureOffer,
	DirectusLeisureOfferCategory,
} from '../../../models/Directus';

import '../../detailview/Detail.scss';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../store/rootReducer';
import { selectScreenData } from '../../../../store/ScreenSlice';

export interface Props {
	item: DirectusLeisureOffer;
	channel?: DirectusChannel;
}

export default function LeisureDetailView(props: Props) {
	const { item, channel } = props;
	const {
		title,
		location,
		content,
		category,
		contact,
		openingtimes,
		link,
	} = item;

	const categoryText = Object.entries(DirectusLeisureOfferCategory).find(
		([key]) => key === category
	)?.[1];

	const screenData = useSelector((state: RootState) =>
		selectScreenData(state.screen)
	);

	if (!channel) {
		return <></>;
	}
	return (
		<>
			<Col lg="12" className="leisure-body">
				<Badge>Freizeit & Erholung</Badge>
				<Row className="leisure-body-row">
					<Col lg="8">
						<Row>
							<h1>{title}</h1>
						</Row>
						<Row className="detail-meta">
							<span>
								in der Kategorie{' '}
								<a
									href={
										'/' +
										screenData?.id +
										'/list/leisure/' +
										channel.id +
										'/' +
										category
									}
								>
									{categoryText}
								</a>
							</span>
						</Row>
						<Row className="text-content">{content}</Row>
					</Col>
					{location && (
						<Col lg="4" className="detail-sidebar">
							<div className="text-details">
								<h5>Adresse</h5>
								<p>
									<a
										href={
											location && location.coords
												? '/' + screenData?.id + '/map/' + location.id
												: undefined
										}
									>
										{location.street}, {location.city}
									</a>
								</p>
								{contact && (
									<>
										<h5>Kontakt</h5>
										<p className="detail-contact">{contact}</p>
									</>
								)}

								{openingtimes && (
									<>
										<h5>Öffnungszeiten</h5>
										<p className="detail-openingtimes">{openingtimes}</p>{' '}
									</>
								)}
                                
                                {link && (
                                    <div className="event-qrcode-parent">
                                            <h5>Mehr erfahren</h5>
                                            <QRCode value={link} size={80} />
                                    </div>
                                )}
							</div>
						</Col>
					)}
				</Row>
			</Col>
		</>
	);
}

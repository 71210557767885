import React from 'react';

import { Alert } from 'react-bootstrap';

import { BreakingNews } from '../../models/Directus';
import './BreakingNewsItem.scss';

interface BreakingNewsItemProps {
	news: BreakingNews[];
}

function BreakingNewsItem(props: BreakingNewsItemProps) {
	const { news } = props;
	const hasNews = news.length > 0;

	const text =
		'Aktuelle Eilmeldungen: ' + news.map((n) => n.text).join(' +++ ') + ' +++ ';
	return (
		<>
			{hasNews && (
				<Alert variant="danger" className="breaking-news-parent">
					<div className="breaking-news">{text}</div>
				</Alert>
			)}
		</>
	);
}

export default BreakingNewsItem;

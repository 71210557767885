import React from 'react';

import { Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import './Start.scss';
import { DirectusChannel } from '../../shared/models/Directus';
import AnnouncementBoxView from '../../shared/views/ChannelBox/Announcement.view';
import ChannelBoxView from '../../shared/views/ChannelBox/ChannelBox.view';
import MapBoxView from '../../shared/views/ChannelBox/MapBox.view';
import { getBreakingNews } from '../../store/BreakingNewsSlice';
import { RootState } from '../../store/rootReducer';
import { selectChannels, selectScreenData } from '../../store/ScreenSlice';

import clsx from 'clsx';

function StartView() {
	const channels = useSelector((state: RootState) =>
		selectChannels(state.screen)
	);

	const screenData = useSelector((state: RootState) =>
		selectScreenData(state.screen)
	);

	const breakingNews = useSelector((state: RootState) =>
		getBreakingNews(state.breakingNews)
	);
	return (
		<Container fluid className="h-100 d-flex flex-column">
			<Row
				className={clsx({
					'h-50': true,
					'start-view': true,
					'has-breaking-news': breakingNews !== null && breakingNews.length > 0,
				})}
			>
				{channels &&
					channels.map((channel: DirectusChannel, index: number) => {
						if (!channel) {
							return null;
						}
						if (channel.category === 'map' && screenData?.location) {
							return (
								<MapBoxView
									mapZoom={screenData.options.mapZoom}
									key={index}
									channel={channel}
									coords={screenData?.location.coords}
								/>
							);
						}
						if (channel.category === 'announcements') {
							return <AnnouncementBoxView key={index} channel={channel} />;
						}
						return channel ? (
							<ChannelBoxView
								key={index}
								channel={channel}
								screenData={screenData}
							/>
						) : null;
					})}
			</Row>
		</Container>
	);
}

export default StartView;

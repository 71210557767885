import { QueryMany } from '@directus/sdk';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useDirectus } from '../hooks/directus';
import { BreakingNews, DirectusExplicitQuery } from '../shared/models/Directus';
import { AppThunk } from './store';

export interface BreakingNewsState {
	news: BreakingNews[];
	loading: boolean;
	error: Error | null;
}

export const initialState: BreakingNewsState = {
	news: [],
	loading: false,
	error: null,
};

const screen = createSlice({
	name: 'breakingnews',
	initialState,
	reducers: {
		getBreakingNewsStart(state) {
			state.loading = true;
			state.error = null;
		},
		getBreakingNewsSuccess(state, action: PayloadAction<BreakingNews[]>) {
			state.news = action.payload;
			state.loading = false;
			state.error = null;
		},
		getBreakingNewsFailed(state, action: PayloadAction<Error>) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

export const {
	getBreakingNewsStart,
	getBreakingNewsSuccess,
	getBreakingNewsFailed,
} = screen.actions;

export default screen.reducer;

export const getBreakingNews = (state: BreakingNewsState) => state.news;

export const selectLoading = (state: BreakingNewsState) => state.loading;

export const onGetBreakingNews = (): AppThunk => async (dispatch) => {
	try {
		const query: QueryMany<BreakingNews> = {
			fields: ['text', 'start', 'end'],
			filter: {
				start: {
					_lte: '$NOW',
				},
			},
		};
		const [result, error, state] = useDirectus(async (client) => {
			/* Directus as of RC83 lost (again) the ability to use nested queries to do an "and" within the query. 
			   So the filter for 'end' is applied by hand while the items are already filter by start time */
			const data = await (await client.items<'breaking_news', BreakingNews>('breaking_news').readMany(query)).data as BreakingNews[];

			return data.filter((m) => new Date((m as BreakingNews).end) > new Date()) as BreakingNews[];
		});

		if (state === 'loading') {
			dispatch(getBreakingNewsStart());
		}

		if (state === 'errored' && error) {
			dispatch(getBreakingNewsFailed(error));
		}

		if (
			state === 'success' &&
			result &&
			Array.isArray(result)
		) {
			const data = result;
			dispatch(getBreakingNewsSuccess(data));
		}

		if (state === 'success' && !result) {
			throw Error('Could not fetch breaking news data');
		}
	} catch (error) {
		dispatch(getBreakingNewsFailed(error));
	}
};

import React from 'react';

import { Col, Image } from 'react-bootstrap';

import activityIconSvg from '../../../../assets/leisure/activity.svg';
import attractionIconSvg from '../../../../assets/leisure/attraction.svg';
import cateringIconSvg from '../../../../assets/leisure/catering.svg';
import cultureIconSvg from '../../../../assets/leisure/culture.svg';
import hotelIconSvg from '../../../../assets/leisure/hotel.svg';
import localIconSvg from '../../../../assets/leisure/local.svg';
import {
	DirectusListItem,
	DirectusScreen,
} from '../../../../shared/models/Directus';
import { LIST_TITLE_LENGTH } from '../../../../Utils/EnvironmentSettings';
import {
	getDistanceText,
	getThumbnail,
	shortText,
} from '../../../../Utils/Utils';

export const displayLeisureListItemContent = (
	screenData: DirectusScreen,
	item: DirectusListItem
): JSX.Element => {
	const imageUrl = getThumbnail(item.image);

	const screenLat = screenData?.location.coords.lat || 1;
	const screenLon = screenData?.location.coords.lon || 1;
	let text = '';
	try {
		text = item.location
			? item.location.street + ', ' + item.location.city
			: '';
	} catch {}

	let icon = <></>;

	if (item.category) {
		const iconMap: { [name: string]: string } = {};
		iconMap['activity'] = activityIconSvg;
		iconMap['attraction'] = attractionIconSvg;
		iconMap['catering'] = cateringIconSvg;
		iconMap['culture'] = cultureIconSvg;
		iconMap['hotels'] = hotelIconSvg;
		iconMap['local'] = localIconSvg;
		if (iconMap[item.category] !== undefined) {
			icon = (
				<img
					alt={item.category}
					className="leisure-icon"
					src={iconMap[item.category]}
				/>
			);
		}
	}

	const textWidth = imageUrl ? 9 : 12;

	return (
		<>
			<Col lg={textWidth}>
				<div className="list-item-text-content-container">
					<h4>
						{shortText(item.title, LIST_TITLE_LENGTH)} {icon}
					</h4>
					<p className="list-item-text-content">
						{text}{' '}
						{item.location &&
							getDistanceText(
								[screenLat, screenLon],
								[item.location.coords.lat, item.location.coords.lon]
							)}
					</p>
				</div>
			</Col>
			{imageUrl && (
				<Col lg="3">
					<Image src={imageUrl} className="list-item-image" />
				</Col>
			)}
		</>
	);
};

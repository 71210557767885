import React from 'react';

import QRCode from 'qrcode.react';
import { Badge, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../store/rootReducer';
import { selectScreenData } from '../../../../store/ScreenSlice';
import { DirectusChannel, DirectusEvent } from '../../../models/Directus';

export interface Props {
	item: DirectusEvent;
	channel?: DirectusChannel;
	allItems?: DirectusEvent[];
}

export default function EventDetailView(props: Props) {
	const { item, channel } = props;
	const {
		title,
		user,
		content,
		date,
		start,
		end,
		location,
		contact,
		link,
	} = item;

	const displayDate = new Date(date).toLocaleDateString('de-DE', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});

	const startTime = start ? start.replace(/:\d+$/, '') : null;
	const endTime = end ? end.replace(/:\d+$/, '') : null;
	const screenData = useSelector((state: RootState) =>
		selectScreenData(state.screen)
	);

	if (!channel) {
		return <></>;
	}

	return (
		<>
			<Col lg="12" className="event-body">
				<Badge>Veranstaltung</Badge>
				<Row className="event-body-row">
					<Col lg="8">
						<Row>
							<h1>{title}</h1>
						</Row>
						<Row className="detail-meta">
							<span>
								von <span className="detail-meta-author">{user.title} </span>
							</span>
						</Row>
						<Row className="text-content">{content}</Row>
					</Col>
					<Col lg="4" className="detail-sidebar">
						<div className="text-details">
							<h5>Datum</h5>
							<p>{displayDate}</p>
							<h5>Zeit</h5>
							<p>
								{startTime}
								{endTime && <span> - {endTime}</span>}
							</p>
							{location && (
								<>
									<h5>Veranstaltungsort</h5>
									<p>
										<a
											href={
												location && location.coords
													? '/' + screenData?.id + '/map/' + location.id
													: undefined
											}
										>
											{location.street}, {location.city}
										</a>
									</p>
								</>
							)}

							{contact && (
								<>
									<h5>Veranstalter</h5>
									<p className="detail-contact">{contact}</p>
								</>
							)}

                            {link && (
                                <div className="event-qrcode-parent">
                                        <h5>Mehr erfahren</h5>
                                        <QRCode value={link} size={80} />
                                </div>
                            )}
						</div>
					</Col>
				</Row>
			</Col>
		</>
	);
}
